<div class="full-height">
  <div *ngIf="bMessageSent">
    <div>
      Your message was recieved.
    </div>
    <p>We will contact you shortly after reviewing your message. <br><br> Thank you.<br>-Business Development Team.
    </p>
  </div>
  <div>{{oErrorMessage}}</div>
  <div *ngIf="!bMessageSent">

    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="form-container">
      <div class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>Clinic Name</mat-label>
          <input matInput formControlName="NAME" class="input-field">
          <mat-error *ngIf="form.get('NAME')?.invalid && form.get('NAME')?.touched">
            Clinic Name is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>Contact Person</mat-label>
          <input matInput formControlName="CONTACT" class="input-field">
          <mat-error *ngIf="form.get('CONTACT')?.invalid && form.get('CONTACT')?.touched">
            Contact Person is required
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>Phone</mat-label>
          <input matInput formControlName="PHONE" class="input-field">
        </mat-form-field>
      </div>
      <div class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>Email</mat-label>
          <input matInput formControlName="EMAIL" class="input-field">
          <mat-error *ngIf="form.get('EMAIL')?.invalid && form.get('EMAIL')?.touched">
            Email is required and must be a valid email address
          </mat-error>
        </mat-form-field>
      </div>
      <div class="form-field">
        <mat-form-field appearance="fill">
          <mat-label>Message</mat-label>
          <textarea matInput formControlName="MESSAGE" class="input-field"></textarea>
          <mat-error *ngIf="form.get('MESSAGE')?.invalid && form.get('MESSAGE')?.touched">
            Message is required
          </mat-error>
        </mat-form-field>
      </div>
      <button mat-raised-button type="submit" color="primary" [disabled]="!form.valid">Submit</button>
    </form>
  </div>
</div>
