import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as moment from 'moment';
// import { TimeInfo, getUnbookedTime  } from '@uqapp/timeslots';

import { Global_Variables } from '../../app/properties';
import { APIService } from '../api.service';
import { Location } from '@angular/common';
// import { MatGridList, MatGridTile } from '@angular/material/grid-list';
// import { MatList, MatListItem } from '@angular/material/list';
import { TitleBarService } from '../title-bar.service';
// import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss']
})
export class AppointmentComponent implements OnInit {
  sTitle = 'Book an Appointment';
  public onConnectionStatusChange(sMqttStatus:any ) {
    this.oTBS.titleChange({ title: this.sTitle, status: sMqttStatus });
  }


  sEntityID = -9;
  sServiceID = -9;

  bLoaded = false;

  oEntity: any;
  oItem: any;
  oOpenHours: any = [];

  oItems:any[] = [];
  constructor(private oTBS: TitleBarService, public oLocation: Location, private oRouter: Router, private oAPIService: APIService) {
    this.onConnectionStatusChange('');
    var oState:any = this.oLocation.getState();
    this.oItem = oState['oServiceItem'];
    this.oEntity = oState['oEntityItem'];
    console.log(this.oEntity);
    console.log(this.oItem);
    // this.oEntity = this.oLocation.getState();
    // console.log(this.oEntity) ;

    if (this.oItem === undefined) {
      this.oRouter.navigateByUrl('entities');
      return;
    }
    //    console.log(this.oItem);
    this.sEntityID = this.oItem.ENTITYID;
    this.sServiceID = this.oItem.ID;
  }

  groupByDay(oItems:any) {
    var groups = oItems.reduce(function (o:any, a:any) {
      var m = a.DAYOFWEEK;
      (o[m]) ? o[m].data.push(a) : o[m] = { DAYOFWEEK: m, data: [a], DOW: Global_Variables.DOW[Number(m)] };
      return o;
    }, {});
    var result = Object.keys(groups).map(function (k) {
      return groups[k];
    });
    //    console.log ( result ) ;
    return result;
  }

  ngOnInit() {
    var today: any = moment();
    var dt = today;
    for (var i = 0; i < 15; i++) {
      this.oItems.push({ date: dt.format('YYYY-MMM-DD'), title: dt.format('DD'), dow: dt.format('ddd') });
      dt = today.add(1, 'd');
    }
    this.oAPIService.send2ServerP("services/" + this.sServiceID + "/wh/fetch").then((data:any) => {
      this.oOpenHours = this.groupByDay(data.result);
      //      console.log(this.oOpenHours);
      var v = this.oItems[0];
      this.onDateClick(v);
      this.bLoaded = true;
    });
  }

  oFreeTimeSlots:any = [];
  oSelectedDateItem:any = null;
  bHasOpenAppointment = false;
  onDateClick(oItem: any) {
    this.bHasOpenAppointment = false;
    this.oSelectedDateItem = oItem;
    //    console.log(oItem);
    this.oSelectedTimeSlot = null;
    var booked_times:any[] = []; // [1000, 1330, 1500, 1100, 1130];
    this.oAPIService.send2ServerP("services/appointments/fetch/" + this.sEntityID + "/" + this.sServiceID, true, { DATE: this.oSelectedDateItem.date }).then((data:any) => {
      //      console.log(data);
      // booked_times = [];
      if (data.result.length > 0) {
        data.result.forEach((e:any) => {
          if (e.UID == Global_Variables.iUserID) {
            this.bHasOpenAppointment = true;
          }
          booked_times.push(e.AQNUMBER);
        });
      }
      var now = moment().format('YYYY-MMM-DD');
      //     console.log("" + moment().format("HHmm"));
      var dtNow = moment(now, "YYYY-MMM-DD");
      this.oFreeTimeSlots = [];
      // console.log(this.oOpenHours);
      this.oOpenHours.forEach((e:any) => {
        if (e.DOW === oItem.dow) {
          // console.log(oItem.dow);
          // console.log(this.oSelectedDateItem.date, now);
          if (moment(this.oSelectedDateItem.date, "YYYY-MMM-DD").isBefore(dtNow, 'day')) {
            // console.log("isBefore");
            return;
          }
          //        console.log(e);
          //          if (moment(this.oSelectedDateItem.date, "YYYY-MMM-DD").isSame(dtNow, 'day') && parseInt(e.data[0].TIME_CLOSE) < parseInt(moment().format("HHmm"))) return;
          this.oFreeTimeSlots = [];
          for (var i = 0; i < e.data.length; i++) {
            console.log(e.data[i].TIME_OPEN)
            this.oFreeTimeSlots = this.oFreeTimeSlots.concat(this.getUnbookedTime(e.data[i].TIME_OPEN, e.data[i].TIME_CLOSE, 30, booked_times));
                      // console.log(this.oFreeTimeSlots);
          }
        }
      });
    });
  }
  oSelectedTimeSlot:any = null;
  onSelectTimeSlot(oTimeSlot: any) {
    if (this.bHasOpenAppointment) return;
    this.oSelectedTimeSlot = oTimeSlot;
    //    console.log(this.oSelectedTimeSlot);
  }
  confirmBooking() {
    //    console.log(this.oSelectedDateItem.date + " " + JSON.stringify(this.oSelectedTimeSlot));
    this.oAPIService.send2ServerP("services/appointments/book/" + this.sEntityID + "/" + this.sServiceID + "", true, { DATE: this.oSelectedDateItem.date, TIME: this.oSelectedTimeSlot.db_time }).then((data:any) => {
      if (data._error) {
        alert("Sorry, that slot was just taken.");
        return;
      }
      //     console.log(data);
      //      this.onDateClick(this.oSelectedDateItem) ;
      this.oRouter.navigateByUrl('myappointments');
    });
  }

  // TODO Once verified use timeslot module - Changed already replicated


// Provides time slots given some interval
 getTime(num:any) {
  var tempHour :any= String(Math.trunc(num / 60));
  var hour = tempHour + "".length === 1 ? "0" + tempHour : tempHour;
  var min = num % 60 === 0 ? "00" : num % 60;
  var sAMPM = 'am';
  var hr = hour;
  if (hour >= 12) {
    hr = hour - 12;
    if (hr == 0) hr = 12;
    sAMPM = 'pm';
  }
  return {
    num: num,
    time: hour + ':' + min,
    db_time: +hour * 100 + (+min),
    time_am_pm: hr + ':' + min + ' ' + sAMPM
  };
}

getTimeSlots(blockTimes:any, showTimeAsString:any, interval:any, includeStartBlockedTime:boolean, includeEndBlockedTime:boolean) {
  var times = 1,
    sums = 60;
    var me = this ;
  includeStartBlockedTime = includeStartBlockedTime === true ? true : false;
  includeEndBlockedTime = includeEndBlockedTime === true ? true : false;
  sums = interval;
  times = 60 / sums;

  var start = 0;
  var dateTimes = Array(Math.round(24 * times))
    .fill(0)
    .map(function (_) {
      start = start + sums;
      return start;
    });
  blockTimes = Array.isArray(blockTimes) === true && blockTimes.length > 0 ? blockTimes : [];
  if (blockTimes.length > 0) {
    dateTimes = blockTimes.reduce(function (acc:any, x:any) {
      return acc
        .filter(function (y:any) {
          return includeStartBlockedTime == true ? y <= x[0] : y < x[0];
        })
        .concat(
          acc.filter(function (y:any) {
            return includeEndBlockedTime == true ? y >= x[1] : y > x[1];
          })
        );
    }, dateTimes);
  }
  if (showTimeAsString === true) {
    return dateTimes
      .map(function (x:any) {
        return me.getTime(x);
      })
      .reduce(function (accc:any, element:any) {
        accc["" + element.num] = element.time;
        return accc;
      }, {});
  }
  return dateTimes;
}

convertTime2Numbers(num:any) {
  //this is what is stored in DB
  console.log("Converting", num, "" + parseInt(num) / 100 * 60, "+", + num % 100)
  return parseInt("" + parseInt(num) / 100) * 60 + num % 100;
}

getUnbookedTime(start_time:any, end_time:any, interval:any, booked_times:any) {
  var me = this ;
  var blocked_times = [];
  for (var i = 0; i < booked_times.length; i++) {
    var b = this.convertTime2Numbers(booked_times[i]);
    blocked_times.push([b, b]);
  }
  var st = this.convertTime2Numbers(start_time);
  var et = this.convertTime2Numbers(end_time);
  var oFree:any = me.getTimeSlots(blocked_times, false, interval, true, false);
  let oAvailableTimes = oFree.filter((time:any) => {
    return (time >= st && time < et);
  }).map(function (x:any) {
    return me.getTime(x);
  });
  return (oAvailableTimes);
}

}

// // console.log(convertTime2Numbers(1100)) ;
// // console.log(convertTime2Numbers(1130)) ;
// // console.log(getTimeSlots([[300,300], [920, 1240]], true, 60));

// // var booked_times = [1000, 1330,1500, 1100, 1130] ;
// // var free_times = getUnbookedTime(1000,1400,30,booked_times) ;
// // console.log(free_times) ;