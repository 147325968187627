<!-- <section class="entity">
 -->
 <div *ngIf="bLoaded" class="full-height">
  <!-- <mat-button-toggle-group #group="matButtonToggleGroup">
    <mat-button-toggle value="left" aria-label="Text align left">
      <mat-icon>format_align_left</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="center" aria-label="Text align center">
      <mat-icon>format_align_center</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="right" aria-label="Text align right">
      <mat-icon>format_align_right</mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="justify" disabled aria-label="Text align justify">
      <mat-icon>format_align_justify</mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group> -->
  <!-- <div class="example-selected-value">Selected value: {{group.value}}</div> -->

  <div class="entity-title">
    {{oEntity.ENAME}}
    <br>{{oEntity.ADDRESS}}
  </div>
  <div class="service-title">
    {{oItem.NAME}}
  </div>
  <div class="scroll">
    <mat-button-toggle-group #group="matButtonToggleGroup" [value]="oItems[0]">
      <div *ngFor="let o of oItems">
        <!-- <span>{{o.title}}</span>
        <span>{{o.dow}}</span>
      </div> -->
        <mat-button-toggle [value]="o" (click)="onDateClick(o)" class="dateDDWWW">
          <p class="day">{{o.title}}</p>
          <p class="dow">{{o.dow}}</p>
        </mat-button-toggle>
      </div>
    </mat-button-toggle-group>
  </div>
  <div class="example-selected-value" *ngIf="!bHasOpenAppointment">Available appointments
    for {{group?.value?.date}}
  </div>
  <!-- <mat-grid-list cols="5" rowHeight="1:1">
    <div *ngFor="let o of oItems">
      <mat-grid-tile>
        <mat-card-title><a mat-raised-button (click)="onDateClick(o)">{{o.dow}}</a><br>
          <a mat-raised-button (click)="onDateClick(o)">{{o.title}}</a></mat-card-title>
      </mat-grid-tile>
    </div>
  </mat-grid-list> -->
  <div class="center">
    <div *ngIf="oSelectedTimeSlot != null" style="padding:10px">
      Selected Appointment <br>{{oSelectedDateItem.date}} @ {{oSelectedTimeSlot.time_am_pm}}
      <!-- <button ion-button (click)="confirmBooking()">Confirm Appointment</button> -->
    </div>
    <div *ngIf="bHasOpenAppointment">You already have an existing appointment on this date. <br>Pls cancel before
      rescheduling</div>
    <div *ngIf="oSelectedTimeSlot != null">
      <a mat-raised-button color="accent" [disabled]="bHasOpenAppointment" full (click)="confirmBooking()">Confirm</a>
    </div>
  </div>
  <mat-grid-list *ngIf="bHasOpenAppointment == false" cols="3" rowHeight="4:2">
    <div class="appointment-slots">
      <mat-grid-tile *ngFor="let f of oFreeTimeSlots">
        <a mat-raised-button class="time" color="accent" [disabled]="bHasOpenAppointment"
          (click)="onSelectTimeSlot(f)">{{f.time_am_pm}}</a>
      </mat-grid-tile>
    </div>
  </mat-grid-list>
  <!-- <mat-card>
  <mat-card-header>Available Slots
    <div *ngIf="oSelectedTimeSlot != null">
      <a mat-raised-button color="accent" [disabled]="bHasOpenAppointment" (click)="confirmBooking()">Confirm
        Appointment</a>
    </div>
  </mat-card-header>
  <mat-card-actions *ngFor="let f of oFreeTimeSlots">
    <a mat-raised-button color="accent" (click)="onSelectTimeSlot(f)">{{f.time_am_pm}}</a>
  </mat-card-actions>
</mat-card> -->
  <!-- https://market.ionicframework.com/themes/doctor-appointment-booking-app -->
  <!-- </section> -->
</div>
