import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Global_Variables } from "../../app/properties";
import { MQTT_HOST, MQTT_PORT, MQTT_USESSL } from "../../env.properties";

// import { LoginServiceProvider } from '../../providers/login-service/login-service';
// import { ApiServiceProvider } from '../../providers/api-service/api-service';
import { APIService } from "../api.service";
import { Paho } from "mosq-mqtt/mqttws31";
import { MqttService } from "abstract-mqtt";
//import { LeftPadPipe } from 'angular-pipes/src/string/left-pad.pipe';
import * as moment from "moment";
import { Location } from "@angular/common";
import { TitleBarService } from "../title-bar.service";
import { Time24to12FormatPipe } from "../time24to12-format.pipe";

@Component({
  selector: "app-my-appointments",
  templateUrl: "./my-appointments.component.html",
  styleUrls: ["./my-appointments.component.scss"],
})
export class MyAppointmentsComponent extends MqttService implements OnInit {
  sTitle = "My Appointments";
  public override onConnectionStatusChange(sMqttStatus:any) {
    this.oTBS.titleChange({ title: this.sTitle, status: sMqttStatus });
  }

  bLoaded: boolean = false;

  oItems:any[] = [];

  oOptions: any = {
    useSSL: MQTT_USESSL,
    userName: "test",
    password: "test12",
  };

  constructor(
    private oTBS: TitleBarService,
    public oLocation: Location,
    private oRouter: Router,
    private oAPIService: APIService
  ) {
    super(MQTT_HOST, MQTT_PORT, Global_Variables, false);
    this.onConnectionStatusChange("");
    super.setMqttOptions(this.oOptions);
  }

  oTodaysAppointment:any[] = [];
  ngOnInit() {
    try {
      this.sMqttHost = "";
      super.setConnect(false);
    } catch (err) {}
    this.oAPIService
      .send2ServerP("services/appointments/mybookings/today", true)
      .then((data: any) => {
        this.oTodaysAppointment = data.result;
        if (this.oTodaysAppointment.length > 0) {
//          var me = this;
          this.sMqttHost = MQTT_HOST; //'m-ezq.ignorelist.com';
          this.setConnect(true);
          this.oTodaysAppointment.forEach((oItem) => {
            //          console.log(oItem);
            this.subscribe("AQ/" + oItem.ENTITYID + "/" + oItem.SERVICEID);
          });
        }
        this.bLoaded = true;
      });
    this.oAPIService
      .send2ServerP("services/appointments/mybookings", true)
      .then((data: any) => {
        this.oItems = data.result;
        //      console.log(this.oItems) ;
        this.bLoaded = true;
      });
  }

  ngOnDestroy() {
    try {
      this.setConnect(false);
    } catch (err) {}
  }

  sMqttHost = MQTT_HOST;
  public override getMqttHost() {
    //    console.log(this.sMqttHost);
    return this.sMqttHost;
  }

  public override getUserName() {
    //TODO get a new JWT token that expires in few minutes.
    return Global_Variables.token?.['access_token'] || '';
  }
  onCancelAppointment(oItem: any) {
    this.oAPIService
      .send2ServerP("services/appointments/cancel", true, { ID: oItem.ID })
      .then((data: any) => {
        this.ngOnInit();
      });
  }

  processMessage(message: Paho.MQTT.Message) {
    // ./pushMessage.sh 'CQ/1/11' '23,<LastQ>'
    var t = super.getTopicPathInArray(message);
    if (t[0] == "ServerUp") {
      return;
    }
    if (t[0] == "IP") {
      this.onConnectionStatusChange(m);
      return;
    }
    var m = super.getMessageInArray(message);
    if (t[0] == "CQ") {
      // this.oItems.forEach((oItem) => {
      //   if (oItem.ENTITYID == t[1] && oItem.SERVICEID == t[2])
      //     oItem.moved = '**';
      //   // oItem.CURRENTAQN = Number(m[0]);
      //   // oItem.CURRENTAQN_T = this.padLeft(oItem.CURRENTQN, '0', 3);
      // });
      this.oTodaysAppointment.forEach((oItem) => {
        //        console.log(oItem);
        if (oItem.ENTITYID == t[1] && oItem.SERVICEID == t[2]) {
          // oItem.CURRENTAQN = Number(m[0]);
          // oItem.CURRENTAQN_T = oItem.CURRENTAQN;
          oItem.moved = "**";
          //          console.log(oItem);
        }
      });
      return;
    }
    if (t[0] == "AQ") {
      // Appointment
      this.oTodaysAppointment.forEach((oItem) => {
        //        console.log(oItem);
        if (oItem.ENTITYID == t[1] && oItem.SERVICEID == t[2]) {
          oItem.CURRENTAQN = Number(m[0]);
          oItem.CURRENTAQN_T = oItem.CURRENTAQN;
          oItem.moved = "";
          //          console.log(oItem);
        }
      });
    }
  }
}
