import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Global_Variables } from '../../app/properties';
import { APIService } from '../api.service';
import { AuthService } from '../auth.service';
import { v4 as uuidv4 } from 'uuid';

const bDebug = true;

@Component({
  selector: 'app-take-queue',
  templateUrl: './take-queue.component.html',
  styleUrls: ['./take-queue.component.scss']
})
export class TakeQueueComponent implements OnInit {

  constructor(private activatedRoute: ActivatedRoute, private oRouter: Router, private oAPIService: APIService, private readonly oAuthService: AuthService) {
  }

  // http://d-ezq.ignorelist.com/?a75bc6ec-344a-006b-28b4-9e949866a485
  // http://localhost:8888/#/takequeue?334b9a07-e8f6-f2a9-f837-14f4891a6ab9

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.queryParamMap.keys !== undefined) {
      Global_Variables.InstantQ = this.activatedRoute.snapshot.queryParamMap.keys[0];
      if (Global_Variables.InstantQ !== undefined && Global_Variables.InstantQ.length > 2
      ) {
        var sCountry = "SG";
        var lat = "0.010";
        var lng = "22.00";
        this.getInstantQNumber(Global_Variables.InstantQ, sCountry, lat, lng);
      }
    }
    console.log(Global_Variables.InstantQ);
  }

  oDemographics: any;
  async getInstantQNumber(sInstantQServiceID:any, sCountry:any, lat:any, lng:any) {
    Global_Variables.InstantQ = ''; // reset
    console.log(sInstantQServiceID);
    Global_Variables.sCountry = sCountry;
    // this.oDemographics = this.oAuthService.getDemographics();
    // if (this.oDemographics !== undefined || this.oDemographics != null) {
    //   Global_Variables.sCountry = this.oDemographics.sCountry;
    // }
    this.oAuthService.clearToken();
    this.oAuthService.saveDemographics({
      sCountry: Global_Variables.sCountry,
      // lat: lat,
      // lng: lng
    });
    var data: any = {};
    // data.DEVICEUID = this.oAuthService.getDeviceID();
    // if (data.DEVICEUID === undefined || data.DEVICEUID == null)
    //   data.DEVICEUID = this.oAuthService.saveDeviceID(uuidv4());
      data.email = this.oAuthService.getDeviceID();
      if (data.email === undefined || data.email == null)
      {
        data.email = this.oAuthService.saveDeviceID(uuidv4());
        data.password = data.email;
      }
      try {
      await this.oAuthService.login(Global_Variables.AUTH_URL, data);
    }
    catch (err) {
      console.error(err);
    }

    //    var b = await (this.oAuthService.loadUserInfo({}));
    this.oAPIService.send2ServerP("instantqn/" + sInstantQServiceID, true, { COUNTRY: sCountry }).then((d: any) => {
      if (d._error)
        console.error(d._error);
      //   // TODO Handle Error Messages like
      //   // EX_Q_UNAVAILABLE
      //   // EX_Q_MAX_REACHED
      //   if (data._error.indexOf("EX_Q_ALREADY_PRESENT") == -1) {
      //     // console.log(data._error);
      //     // this.oRouter.navigateByUrl('entities');
      //     return;
      //   }
      //    }
      this.oRouter.navigateByUrl('queuestatus');
    });
  }
}
