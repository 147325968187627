<div class="full-height">
  <div *ngIf="!bLoaded">
    <mat-progress-spinner class="custom-spinner" diameter="40" mode="indeterminate">
    </mat-progress-spinner>
  </div>
  <div *ngIf="bErrorLoading">
    <a mat-raised-button>Click here to Retry</a>
  </div>
  <div *ngIf="bLoaded">
    <!-- <section class="entity">
    <mat-card fxFlex.xs="100%" class="entity-card" *ngFor="let o of oItems">
      <mat-card-title>{{o.ENAME}}</mat-card-title>
      <img no-padding [src]="oAPIService.buildImageURL(o)" class="ksd-image">
  <mat-card-content>{{o.ADDRESS}}</mat-card-content>
  <mat-card-actions>
    <button mat-button (click)="onEntityClick(o)">Services</button>
    <button mat-button (click)="onBusinessHours(o)">Open Hours</button>
  </mat-card-actions>
  </mat-card>
  </section> -->
    <!-- <section class="entity">
    <div class='grid--thumbs'> -->
    <mat-card appearance="outlined" *ngFor="let o of oItems">
      <mat-card-title>
        <div class="entity-title">
          {{o.ENAME}}<br>{{o.ADDRESS}}
        </div>
      </mat-card-title>
      <!-- <img no-padding [src]="oAPIService.buildImageURL(o)" class="ksd-image"> -->
      <mat-card-content>
        <mat-grid-list cols="2">
          <mat-grid-tile><a color="accent" mat-raised-button (click)="onEntityClick(o)">Services</a>
          </mat-grid-tile>
          <!-- <mat-grid-tile><a color="accent" mat-raised-button (click)="onBusinessHours(o)">Open Hours</a>
          </mat-grid-tile> -->
        </mat-grid-list>
      </mat-card-content>
    </mat-card>
  </div>
  <!-- </section>
</div> -->
</div>
