import { sEndPoint } from "../env.properties";
//import { UUID } from "angular2-uuid";
import { v4 as uuidv4 } from 'uuid';

export const sURL = sEndPoint + "api/";
export const sIMAGE_URL = sEndPoint + "image/";
export var Global_Variables = {
  iUserID: "",
  sUserType: "N",
  isConnected: true,
  isMobile: false,
  token: null,
  DeviceID: "None",
  oEID: -1,
  sEntityID: 0,
  //  oRItem: null,
  //  oMenuItems: [],
  sCurrentPage: "entities",
  bSaved: false,
  InstantQ: "",
  sDeviceUUID: null,
  sDeviceReg: null,
  sCountry: "",
  sCity: "",
  sTZ: "",
  sNewDeviceReg: "",
  DOW: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "PH"],
  UUID: uuidv4(),
//  AUTH_URL: "auth" // NodeJS
  AUTH_URL: "api/uuid/login" // Rust
};

export const sDOWNLOAD_URL = sEndPoint + "download/uat/yq";
