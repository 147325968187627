import { Injectable, Inject } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { APIService } from "./api.service";
import { LOCAL_STORAGE, StorageService } from "ngx-webstorage-service";
import { Global_Variables } from "../app/properties";
import { jwtDecode } from "jwt-decode";

const STORAGE_KEY = "token";
const DEMOGRAPICS = "location";
const DEVICE_UID = "user_identification";

@Injectable({
  providedIn: "root",
})
export class AuthService {

  private oAPIService: APIService|null = null;

  public isAuthenticated = new BehaviorSubject<boolean>(false);

  constructor(
    @Inject(APIService) private oAPI:any,
    @Inject(LOCAL_STORAGE) private oStorage: StorageService,
    private router: Router
  ) {
    this.oAPIService = oAPI;
    this.clearToken();
  }

  async checkAuthenticated() {
    console.info("checkAuthenticated");
    const authenticated = await this.exists();
    console.info(authenticated);
    if (authenticated)
      this.isAuthenticated.next(authenticated);
    return authenticated;
  }

  async login(sService: string, data: any, sRedirectURL: string |null = null) {
    try {
      const id = await this.oAPIService?.performLogin(sService, data);
      // console.info(id);
      Global_Variables.token = id;
      if (id) {
        console.log("***ID", id)
        if (id['status'] == 'error') {
          console.error("*** FATAL Login Failed. ", id);
        }
        // let o = this.jwtHelper.decodeToken(id.access_token);
        let o:any = jwtDecode(id.access_token)
        let user_fields = JSON.parse(o.user_fields)
        // console.log(user_fields)
        // o = o.data;
        // Global_Variables.sEntityID = o.ENTITYID;
        Global_Variables.iUserID = user_fields.UID;
        Global_Variables.sUserType = user_fields.TYPE;
        Global_Variables.token = id;
        this.oStorage.set(STORAGE_KEY, id);
        // console.log(Global_Variables)
        this.isAuthenticated.next(true);
        if (sRedirectURL != null) this.router.navigate([sRedirectURL]);
        return;
      }
    } catch (err:any) {
      console.error(err);
      // Global_Variables.token = "SkipValidation"
      // this.isAuthenticated.next(true);
      // await this.router.navigate(['qdisplay']);
      // return ;
      throw Error(JSON.stringify(err.error));
    }
    // console.log("Not Authenticated") ;
    // throw Error('Not Authenticated ');
  }

  async logout(redirect: string) {
    try {
      this.oStorage.remove(STORAGE_KEY);
      Global_Variables.token = null;
      this.isAuthenticated.next(false);
      this.router.navigate([redirect]);
    } catch (err) {
      console.error(err);
    }
  }
  getDeviceID() {
    return this.oStorage.get(DEVICE_UID);
  }
  saveDeviceID(sDeviceID: string) {
    this.oStorage.set(DEVICE_UID, sDeviceID);
    return sDeviceID;
  }
  getDemographics() {
    return this.oStorage.get(DEMOGRAPICS);
  }
  saveDemographics(o: any) {
    // if (o.latitude === undefined) {
    //   var t = this.oStorage.get(DEMOGRAPICS);
    //   if (t !== undefined) {
    //     o.lat = t.latitude;
    //     o.lng = t.latitude;
    //   }
    // }
    this.oStorage.set(DEMOGRAPICS, o);
  }
  clearToken() {
    this.oStorage.remove(STORAGE_KEY);
    Global_Variables.bSaved = false;
  }
  exists() {
    var id = this.oStorage.get(STORAGE_KEY);
    if (id) {
      try {
        console.log(">>ID", id)
        let o:any = jwtDecode(id.access_token)
        let user_fields = JSON.parse(o.user_fields) ;
        // console.info(user_fields);
        Global_Variables.sEntityID = user_fields.ENTITYID;
        Global_Variables.iUserID = user_fields.UID;
        Global_Variables.sUserType = user_fields.TYPE;
        Global_Variables.token = id;
        //TODO check for Expiry
        return true;
      } catch (err) {
        return false;
      }
    }
    return false;
  }
  storeDeviceRegistrationInfo() {
    // NOT TESTED
    if (Global_Variables.bSaved) return;
    if (
      Global_Variables.sNewDeviceReg != null &&
      Global_Variables.sDeviceReg != Global_Variables.sNewDeviceReg
    ) {
      // console.log("*** NEW DEVICE *** ", Global_Variables.sNewDeviceReg);
      // this.oAPIService.send2ServerP("registerdevice/" + Global_Variables.sNewDeviceReg, true, { DEVICEUID: Global_Variables.sDeviceUUID }).then((data: any) => {
      //   Global_Variables.bSaved = true;
      // });
    }
    return;
  }

  async loadUserInfo(data: any) {
    if (this.exists()) return true;
    return this.login(Global_Variables.AUTH_URL, data, "entities");
  }
}
