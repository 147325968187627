import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Global_Variables } from "../../app/properties";
import { MQTT_HOST, MQTT_PORT, MQTT_USESSL } from "../../env.properties";
import * as moment from "moment-timezone";
//import countriesAndTimezones from "countries-and-timezones";
import { MqttService } from "abstract-mqtt";
import { APIService } from "../api.service";
import { Location } from "@angular/common";
import { Paho } from "mosq-mqtt/mqttws31";
// import { MatGridList, MatGridTile } from "@angular/material/grid-list";
// import { MatList, MatListItem } from "@angular/material/list";
import { TitleBarService } from "../title-bar.service";
//import { UUID } from "angular2-uuid";

const NO_QUEUE_MESSAGE = "Services are shown during Business Hours only";
const NO_SERVICES_MESSAGE = "No Services Defined";

@Component({
  selector: "app-services",
  templateUrl: "./services.component.html",
  styleUrls: ["./services.component.scss"],
})
export class ServicesComponent
  extends MqttService
  implements OnInit, OnDestroy {
  sTitle: string = "Services";

  sMessage: string|null = null;

  products = [];
  oEntity: any = {};
  oItems: any[] = [];
  bDisabled = true;
  bLoaded: boolean = false;

  bDebug = true;
  // oItem: any = { CURRENTQN: 0, ISSUEDQN: 0 };
  // oAItem: any = { CURRENTQN: 0, ISSUEDQN: 0 };
  oService: any = {};
  COUNTERN: number = -1;
  //  bHasMore: boolean = false;
  sEmail: string = "";
  bIsServicingAppointment = false;

  oOptions: any = {
    useSSL: MQTT_USESSL,
    userName: "test",
    password: "test12",
  };

  //  bShouldConnect = false;

  sMqttHost = MQTT_HOST;
  public override getMqttHost() {
    return this.sMqttHost;
  }
  public override getClientID() {
    //  super.sClientID = "qs-" + this.Global_Variables.UUID;
    // return uuidv4();
    return "qs-" + Global_Variables.UUID;
  }

  // public getClientID() {
  //   super.sClientID = "svc-" + this.Global_Variables.UUID;
  //   return super.sClientID;
  // }
  constructor(
    private oTBS: TitleBarService,
    public oLocation: Location,
    private oRouter: Router,
    private oAPIService: APIService
  ) {
    super(MQTT_HOST, MQTT_PORT, Global_Variables, false);
    this.oEntity = this.oLocation.getState();
    if (this.oEntity.ID === undefined) {
      this.oRouter.navigate([""]);
      return;
    }
    console.log(">>>>> Entitiy", this.oEntity.ID)
    // this.Global_Variables.UUID = undefined;
    super.setMqttOptions(this.oOptions);
    this.COUNTERN = -1;

    this.sMessage = null;

    //    console.log(this.oEntity.ID, Global_Variables.oEID);
    //this.sMqttHost = MQTT_HOST; //'m-ezq.ignorelist.com';
    // this.oAPIService.send2ServerP("mqtthost/" + this.oEntity.ID, true).then((data: any) => {
    //   var o = data.result;
    //   console.log(o);
    //   if (o.length > 0) {
    //     this.sMqttHost = o[0].MQTTHOST;
    //     super.setConnect(true);
    //     // super.setConnect(this.bShouldConnect);
    //     console.log(this.sMqttHost);
    //   }
    // });
    console.log(">>>>> Entitiy", this.oEntity.ID)

    this.oAPIService
      .send2ServerP("services/" + this.oEntity.ID + "/f", true)
      .then((data: any) => {
        this.oItems = data.result;
        this.sMessage = null;
        //      console.log(this.oItems);
        if (this.oItems.length > 0) {
          // this.sMqttHost = this.oItems[0].MQTTHOST;
          super.setConnect(true);
          this.oItems.forEach((oItem, index) => {
            //          console.log("*** ", oItem);
            if (oItem._D.length > 0)
              oItem._D[0].QUEUEN_T = this.padLeft(oItem._D[0].QUEUEN, "0", 3);
            if ( oItem.ISSUEDQN !== null )
            {
              oItem.CURRENTQN_T = this.padLeft(oItem.CURRENTQN, "0", 3);
              oItem.ISSUEDQN_T = this.padLeft(oItem.ISSUEDQN, "0", 3);
            }
            this.setCanQueueUpNow(oItem);
            if (index == this.oItems.length - 1) {
              setTimeout(() => {
                this.bLoaded = true;
              }, 500);
            }
          });
          super.subscribe("CQ/" + Global_Variables.oEID + "/#");
        } else {
          super.setConnect(false);
          //        this.bShouldConnect = false;
          this.sMessage = NO_SERVICES_MESSAGE;
          this.bLoaded = true;
        }
      });
  }

  public override onConnectionStatusChange(sMqttStatus:any) {
    this.oTBS.titleChange({ title: this.sTitle, status: sMqttStatus });
  }
  public override getUserName() {
    //TODO get a new JWT token that expires in few minutes.
    return Global_Variables.token?.['access_token']|| '';
  }
  goToStatus() {
    // try {
    //   super.setConnect(false);
    // }
    // catch (err) { }
    this.oRouter.navigate(["queuestatus"]);
  }
  padLeft(text: any, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  }
  isClosedNow(oItem: any) {
    oItem.bClosed = true;
    oItem._BusinessHours.forEach((o: any) => {
      //      console.log(o);
      //get current time and check if it is inbetwen
      var currentTime = moment(o.LOCAL_TIME).format("Hmm");
      // console.log ( "TIMEZONE: " +  moment().parseZone().localeData ) ;

      var iNow = Number(currentTime);
      var iTO = Number(o.TIME_OPEN);
      var iTC = Number(o.TIME_CLOSE);
      // console.log("Comparing: " + currentTime + " with " + moment(o.TIME_OPEN).format("Hmm") + " and " + moment(o.TIME_CLOSE).format("Hmm"));
      // if (moment(currentTime).isBetween(moment(o.TIME_OPEN, "Hmm"), moment(o, "Hmm")))
      if (iNow >= iTO && iNow <= iTC) {
        oItem.TIME_OPEN = iTO;
        oItem.TIME_CLOSE = iTC;
        //     console.log("Open NOW...");
        oItem.bClosed = false;
      }
      oItem.bLoading = false;
    });
  }

  // onPurchase(oItem: any) {
  //   //https://medium.com/@andrew.thielcole/in-app-purchases-with-ionic-3-af13b21f49f2
  //   // ionic cordova plugin add cc.fovea.cordova.purchase --variable BILLING_KEY="<ANDROID_BILLING_KEY>"
  //   // $ npm install --save @ionic-native/in-app-purchase-2

  // }
  onBookAppointment(oItem: any) {
    console.log(oItem);
    //send oService
    this.oRouter.navigateByUrl("appointment", {
      state: { oServiceItem: oItem, oEntityItem: this.oEntity },
    });
    //   this.navCtrl.setRoot('AppointmentPage') ;
  }
  onGetQN(oItem: any) {
    //    this.oIAP.buy(this.products[0]).then(data => {
    /*
   data will contain {
     transactionId: ...
     receipt: ...
     signature: ...
   }
 */
    this.oAPIService
      .send2ServerP("gqn/" + Global_Variables.oEID + "/" + oItem.ID, true)
      .then((data: any) => {
        if (data._error) {
          console.error(data._error);
          return;
        }
        // Required for Android.  However, can be called for iOS as well.
        //      this.oIAP.consume ( data.productType, data.receipt, data.signature ) ;
        this.goToStatus();
      });
    //  });
  }
  setCanQueueUpNow(oItem: any) {
    oItem.bLoading = true;
    this.oAPIService
      .send2ServerP("q/opened/" + Global_Variables.oEID + "/" + oItem.ID)
      .then((data: any) => {
        //      console.log(JSON.stringify(data.result));
        if (data.result?.length > 0) {
          //        this.zone.run(() => {
          oItem._bQOpened = true;
          oItem._BusinessHours = data.result;
          this.isClosedNow(oItem);
          //          console.log("Setting Opened = TRUE");
          //        });
        } else {
          oItem.bClosed = true;
          oItem.PREFIX = "";
          oItem.CURRENTQN_T = "closed";
          oItem.ISSUEDQN_T = "closed";
        }
      });
  }
  async onBusinessHours(oItem: any) {
    oItem.ENAME = this.oEntity.ENAME;
    await this.oRouter.navigateByUrl("businesshours", { state: oItem });
  }

  ngOnInit() {
    var me = this ;
    this.oTBS.oCurrentTitle.subscribe(obj => {
      console.log("Connection", obj)
      setTimeout(() => {
        this.sTitle = obj.title;
      
        me.oMqttStatus = obj.status;
        // console.log(this.sColor, this.sMqttStatus);
      }, 1);
    });

    // this.sMessage = null;
    // if (this.bLoaded && this.oItems.length > 0) {
    //   // this.bShouldConnect = true;
    //   //      super.subscribe("CQ/" + Global_Variables.oEID + "/#");
    //   super.setConnect(true);
    //   this.oItems.forEach(oItem => {
    //     this.setCanQueueUpNow(oItem);
    //   })
    // }
    // else {
    //   this.sMessage = NO_SERVICES_MESSAGE;
    // }
  }
  ngOnDestroy() {
    try {
      super.setConnect(false);
      super.disconnectMQTT();
    } catch (err) {
      //      console.log("ERROR ", err);
    }
  }

  processMessage(message: Paho.MQTT.Message) {
    // ./pushMessage.sh 'CQ/1/11' '21,49'
    //    var t = message.destinationName.split('/');
    //    console.log(d[0] + " " + d[1] + " " + d[2]);
    //    var m = message.payloadString.split(',');
    //    console.log(m[0] + " and " + m[1]);
    var t = super.getTopicPathInArray(message);
    var m = super.getMessageInArray(message);

    if (t[0] == "ServerUp") {
      return;
    }
    if (t[0] == "IP") {
      this.onConnectionStatusChange(m);
      return;
    }
    if (t[0] == "CQ") {
      this.oItems.forEach((oItem) => {
        if (oItem.ID == t[2]) {
          // if ( m[0] == '0' && m[1] == '0')
          //   return ;
          oItem.CURRENTQN = Number(m[0]);
          oItem.CURRENTQN_T = this.padLeft(oItem.CURRENTQN, "0", 3);
          oItem.ISSUEDQN = Number(m[1]);
          oItem.ISSUEDQN_T = this.padLeft(oItem.ISSUEDQN, "0", 3);
        }
      });
    }
  }
}
