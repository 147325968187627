<!-- <div>{{oErrorMessage}}</div>
<div class="form-container">
  <json-schema-form [schema]="oFormSchema" [layout]="oFormLayout" [(data)]="oFormData" framework="material-design"
    (onSubmit)="onFormSubmit($event)" (isValid)="isFormValid($event)" loadExternalAssets="true">
  </json-schema-form>
</div> -->
<!-- <div class="title">
  {{ "My coordinates" | lang: currentLang }}
  <a
    href="https://github.com/m-pavlov/angular-geolocation-pwa"
    class="github"
    target="_blank"
    >github</a
  >
</div> -->
<!-- <app-view-type (choose)="onTypeChange($event)"></app-view-type> -->

<div *ngIf="bLoaded">
  <h5>Country {{ sCountry }}</h5>
  <button id="action" class="btn btn-orange btn-radius" (click)="showEntities()">Near Me</button>
</div>
<!-- 

<div *ngIf="errorType || !position" class="errors">
  <div *ngIf="errorType === 'not-available'">
    {{
      "Sorry, geolocation is not available on your device" | lang: currentLang
    }}
  </div>
  <div *ngIf="errorType === 'failed'">
    {{ "Sorry, we can't get your geolocation" | lang: currentLang }}
  </div>
  <div *ngIf="errorType === 'permission'">
    {{ "Please, allow the browser to know your location" | lang: currentLang }}
  </div>
</div>
<div *ngIf="position && !errorType" class="position">
  <div class="label">{{ "Latitude" | lang: currentLang }}</div>
  <div class="values" *ngIf="position.latitude !== null">
    <span *ngIf="viewType === 'decimal'" class="value">{{
      position.latitude | decimal
    }}</span>
    <span *ngIf="viewType === 'minutes'" class="value">{{
      position.latitude | minutes
    }}</span>
    <span *ngIf="position.latitude > 0">{{ "N" | lang: currentLang }}</span>
    <span *ngIf="position.latitude < 0">{{ "S" | lang: currentLang }}</span>
    <span *ngIf="position.accuracyLatitude !== null" class="accuracy">
      &plusmn;
      <span *ngIf="viewType === 'decimal'">{{
        position.accuracyLatitude | decimal
      }}</span>
      <span *ngIf="viewType === 'minutes'">{{
        position.accuracyLatitude | minutes
      }}</span>
    </span>
  </div>
  <div class="values" *ngIf="position.latitude === null">
    <span class="value">{{ "not available" | lang: currentLang }}</span>
  </div>

  <div class="values" *ngIf="position.longitude !== null">
    <span *ngIf="viewType === 'decimal'" class="value">{{
      position.longitude | decimal
    }}</span>
    <span *ngIf="viewType === 'minutes'" class="value">{{
      position.longitude | minutes
    }}</span>
    <span *ngIf="position.latitude > 0">{{ "E" | lang: currentLang }}</span>
    <span *ngIf="position.latitude < 0">{{ "W" | lang: currentLang }}</span>
    <span *ngIf="position.accuracyLongitude !== null" class="accuracy">
      &plusmn;
      <span *ngIf="viewType === 'decimal'">{{
        position.accuracyLongitude | decimal
      }}</span>
      <span *ngIf="viewType === 'minutes'">{{
        position.accuracyLongitude | minutes
      }}</span>
    </span>
  </div>
  <div class="values" *ngIf="position.longitude === null">
    <span class="value">{{ "not available" | lang: currentLang }}</span>
  </div>

  <div class="label">{{ "Altitude" | lang: currentLang }}</div>
  <div class="values" *ngIf="position.altitude !== null">
    <span class="value">{{ position.altitude | decimal: true }}</span>
    <span>{{ "meters" | lang: currentLang }}</span>
    <span *ngIf="position.accuracyAltitude !== null" class="accuracy">
      &plusmn;
      {{ position.accuracyAltitude }}
    </span>
  </div>
  <div class="values" *ngIf="position.altitude === null">
    <span class="value">{{ "not available" | lang: currentLang }}</span>
  </div>
</div>
<div *ngIf="!bHasLocation">
  <p>Location Services</p>
  <p>
    Trying to obtain location. Ensure location services are enabled, and you are
    in a place where the device can obtain your position.
  </p>
  <p>LOCATION SETTINGS ( TODO To take to location )</p>
  <p (click)="getLocation()">Click here</p>
</div>
<div *ngIf="!bLoaded && bHasLocation">
  <div class="center">
    <mat-progress-spinner
      class="custom-spinner"
      diameter="40"
      mode="indeterminate"
    >
    </mat-progress-spinner>
  </div>
  <p>{{ sError }}</p>
</div> -->

<div *ngIf="bLoaded">
  <div>
    <p>Country {{ sCountry }}</p>
  </div>
  <!-- <button color="accent" mat-raised-button round full (click)="showEntities()">
    Click here
  </button> -->
</div>
<!-- <p>{{ errMsg }}</p> -->

<!-- <div *ngIf="bLoaded">
  <img [src]="sImage">
</div> -->

<!-- <div *ngIf="Global_Variables.isMobile == false">
  <p>Checking if it is Mobile Browser</p>
</div> -->
