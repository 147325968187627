<div *ngIf="bLoaded" class="full-height">
  <div *ngIf="oItems.length == 0">
    <p class="centered-message">
      Currently you don't have any active Queue number(s).
    </p>
  </div>
  <div *ngFor="let o of oItems">
    <div class="entity-title">
      {{ o.ENAME }}
      <br />{{ o.ADDRESS }}
    </div>
    <mat-card appearance="outlined">
      <mat-card-title class="service-title">
        <mat-grid-list cols="2" rowHeight="4:1">
          <mat-grid-tile>{{ o.NAME }}</mat-grid-tile>
          <div *ngIf="!o.bClosed" text-center class="ksd-smallfont">
            <mat-grid-tile
              >{{ o.TIME_OPEN | timeFormat }} -
              {{ o.TIME_CLOSE | timeFormat }}</mat-grid-tile
            >
          </div>
          <div *ngIf="o.bClosed">
            <mat-grid-tile>closed</mat-grid-tile>
          </div>
        </mat-grid-list>
      </mat-card-title>
      <mat-card-content>
        <mat-divider></mat-divider>
        <mat-grid-list cols="2" rowHeight="4:1">
          <mat-grid-tile>Your Q#</mat-grid-tile>
          <mat-grid-tile *ngIf="!o.bClosed">Now Servicing</mat-grid-tile>
          <mat-grid-tile *ngIf="o.bClosed">Last Serviced</mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="4:1">
          <mat-grid-tile *ngIf="o.CURRENTQN &lt; o.QUEUEN" class="ksd-mq">
            {{ o.PREFIX | lpad: 2:" " }}{{ o.QUEUEN_T }}<br />
          </mat-grid-tile>
          <mat-grid-tile *ngIf="o.CURRENTQN > o.QUEUEN" class="ksd-mq-missed">
            {{ o.PREFIX | lpad: 2:" " }}{{ o.QUEUEN_T }}</mat-grid-tile
          >
          <mat-grid-tile
            *ngIf="o.CURRENTQN == o.QUEUEN"
            class="ksd-mq-calling blinking"
          >
            {{ o.PREFIX | lpad: 2:" " }}{{ o.QUEUEN_T }}</mat-grid-tile
          >
          <mat-grid-tile class="ksd-cq"
            >{{ o?.PREFIX | lpad: 2:" " }}{{ o?.CURRENTQN_T }}</mat-grid-tile
          >
        </mat-grid-list>
        <mat-grid-list cols="2" rowHeight="8:1">
          <mat-grid-tile *ngIf="o.CURRENTQN &lt; o.QUEUEN" class="ksd-mq">
            <a
              color="accent"
              mat-raised-button
              round
              full
              (click)="o.bCanRequeue = false; onGiveUpQ(o)"
            >
              Give Up Q#</a
            >
          </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="1" rowHeight="4:1">
          <mat-grid-tile
            >Last in Q#
            <span class="ksd-lq"
              >&nbsp;&nbsp;{{ o.PREFIX | lpad: 2:" "
              }}{{ o.LASTQ_T }}&nbsp;&nbsp;</span
            >
          </mat-grid-tile>
          <mat-grid-tile *ngIf="!o.bLoading">
            <!-- && o.CURRENTQN &lt; o.QUEUEN && !o.bClosed -->
            <!-- <div>
              <button color="accent" mat-raised-button round full (click)="o.bCanRequeue = false; onGiveUpQ(o)">
                Give Up Q#</button>
            </div> -->
            <div *ngIf="!o.bClosed && o.bCanRequeue">
              <button
                color="accent"
                mat-raised-button
                round
                full
                (click)="o.bCanRequeue = false; onRequeue(o)"
              >
                Requeue
              </button>
            </div>
          </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list cols="3" rowHeight="4:3">
          <mat-grid-tile *ngIf="o.Loading">fetching...</mat-grid-tile>
          <mat-grid-tile *ngIf="o.UsersInQ > 0"
            >{{ o.UsersInQ }} person(s) are in front</mat-grid-tile
          >
          <mat-grid-tile class="left"> </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list
          cols="3"
          rowHeight="4:1"
          *ngIf="!o.bClosed && o.UsersInQ > 1"
        >
          <mat-grid-tile>
            <a color="accent" mat-raised-button round (click)="onExchangeQN(o)">
              <mat-icon svgIcon="ksd-exchange"></mat-icon>
            </a>
          </mat-grid-tile>
          <mat-grid-tile
            ><a
              color="accent"
              mat-raised-button
              round
              [disabled]="o.bShowFreeSlots == false"
              (click)="showFreeSlots(o, 0)"
            >
              <mat-icon svgIcon="ksd-left"></mat-icon> </a
          ></mat-grid-tile>
          <mat-grid-tile>
            <a
              color="accent"
              mat-raised-button
              round
              [disabled]="o.bShowFreeSlots == false"
              (click)="showFreeSlots(o, 1)"
            >
              <mat-icon svgIcon="ksd-right"></mat-icon>
            </a>
          </mat-grid-tile>
          <mat-grid-tile *ngIf="o.bShowFreeSlots">
            <mat-grid-list class="ksd-items" *ngFor="let oo of o.oFreeSlots">
              <mat-grid-tile>
                <a
                  color="accent"
                  mat-raised-button
                  round
                  full
                  (click)="onExchangeWith(o, oo)"
                  >Exchange with Q# {{ o.PREFIX
                  }}{{ oo?.AVAILABLEQN.toString() | lpad: 3:"0" }}
                </a>
              </mat-grid-tile>
            </mat-grid-list>
          </mat-grid-tile>
        </mat-grid-list>
      </mat-card-content>
    </mat-card>
  </div>
</div>
