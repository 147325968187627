<!-- https://www.positronx.io/build-progressive-web-app-pwa-with-angular/ -->
<!-- UI Testing 
  https://stackblitz.com/edit/angular-mat-card-example?file=src%2Fapp%2Fapp.component.html 
-->
<!-- <mat-toolbar class="header">
  <mat-toolbar-row>
    <span>Services</span>
    <span class="fill-space"></span>
    <span class="ksd-mqtt-connected">{{oMqttStatus}}</span>
  </mat-toolbar-row>
</mat-toolbar> -->
<div *ngIf="bLoaded" class="full-height">
  <div class="entity-title">
    {{oEntity.NAME}}
    <br>{{oEntity.ADDRESS}}
  </div>
  <div *ngIf="sMessage != null">
    <p>{{sMessage}}</p>
  </div>
  <!-- <section *ngFor="let o of oItems" padding="10px;"> -->
  <mat-card appearance="outlined" *ngFor="let o of oItems">
    <!-- <mat-divider></mat-divider> -->
    <mat-card-title class="service-title">
      <mat-grid-list cols="2" rowHeight="4:1">
        <mat-grid-tile>{{o.NAME}}</mat-grid-tile>
        <div *ngIf="!o.bClosed" text-center class="ksd-smallfont">
          <mat-grid-tile>{{o.TIME_OPEN | timeFormat }} - {{o.TIME_CLOSE | timeFormat }}</mat-grid-tile>
        </div>
        <div *ngIf="o.bClosed">
          <mat-grid-tile>closed</mat-grid-tile>
          <mat-grid-tile><a color="accent" mat-raised-button (click)="onBusinessHours(o)">Open Hours</a>
          </mat-grid-tile>

        </div>
      </mat-grid-list>
    </mat-card-title>
    <mat-card-content>
      <mat-grid-list cols="2" rowHeight="4:1">
        <mat-grid-tile>Now AA Servicing</mat-grid-tile>
        <mat-grid-tile>Last</mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="2" rowHeight="4:1">
        <mat-grid-tile>{{o?.PREFIX | lpad: 2: ' '}}{{o?.CURRENTQN_T}}</mat-grid-tile>
        <mat-grid-tile>{{o?.PREFIX | lpad: 2: ' '}}{{o?.ISSUEDQN_T}}</mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="2" rowHeight="3:1">
        <div *ngIf="o._D.length == 0">
          <div *ngIf="!o.bClosed">
            <mat-grid-tile><a color="accent" mat-raised-button (click)="onGetQN(o)">Join Queue</a></mat-grid-tile>
          </div>
          <div *ngIf=" o.bClosed">
            <mat-grid-tile>Closed</mat-grid-tile>
          </div>
        </div>
        <div *ngIf="o._D.length > 0">
          <mat-grid-tile><a mat-button block color="accent" (click)="goToStatus()">Your Q#
              {{o.PREFIX | lpad: 2: ' '}}{{o._D[0].QUEUEN_T}}</a>
          </mat-grid-tile>
        </div>
        <mat-grid-tile><a color="accent" mat-raised-button (click)="onBookAppointment(o)">Book Appointment</a>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-divider></mat-divider>
    </mat-card-content>
  </mat-card>
  <!-- </section> -->

</div>


<!-- <div>
  <mat-card *ngFor="let o of oItems">
    <mat-card-content>
      <mat-grid-list cols="2" rowHeight="4:1">
        <mat-grid-tile><a color="accent" mat-raised-button (click)="onEntityClick(o)">Services</a>
        </mat-grid-tile>
        <mat-grid-tile><a color="accent" mat-raised-button (click)="onBusinessHours(o)">Open Hours</a>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>
  </mat-card>
</div> -->
