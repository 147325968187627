import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Global_Variables } from "../../app/properties";
import { TitleBarService } from "../title-bar.service";
import { APIService } from "../api.service";
import { AuthService } from "../auth.service";
import { FormControl } from "@angular/forms";
import { MqttService } from "abstract-mqtt";
import { MQTT_HOST, MQTT_PORT, MQTT_USESSL } from "../../env.properties";

// import { Location } from "@angular/common";
import { Paho } from "mosq-mqtt/mqttws31";
import * as moment from "moment";
// import { MatGridList, MatGridTile } from "@angular/material/grid-list";
// import { MatList, MatListItem } from "@angular/material/list";
// import { UUID } from "angular2-uuid";

const NO_QUEUE_MESSAGE = "Services are shown during Business Hours only";
const NO_SERVICES_MESSAGE = "No Services Defined";

@Component({
  selector: "app-clinics",
  templateUrl: "./clinics.component.html",
  styleUrls: ["./clinics.component.scss"],
})
export class ClinicsComponent extends MqttService implements OnInit, OnDestroy {
  searchControl: FormControl| undefined = undefined;
  bSearching: any;
  bLoaded = false;
  sCountry: any = "None";
  sTZ: any = "TZ";

  oEntities: any[] = [];
  oServices: any[] = [];

  oEntity: any = undefined;
  sMessage: string|null = null;

  products = [];
  bDisabled = true;

  bDebug = true;
  // oItem: any = { CURRENTQN: 0, ISSUEDQN: 0 };
  // oAItem: any = { CURRENTQN: 0, ISSUEDQN: 0 };
  //  oService: any = {};
  COUNTERN: number = -1;
  //  bHasMore: boolean = false;
  sEmail: string = "";
  bIsServicingAppointment = false;

  oOptions: any = {
    useSSL: MQTT_USESSL,
    userName: "test",
    password: "test12",
  };

  sMqttHost = MQTT_HOST;
  public override getMqttHost() {
    return this.sMqttHost;
  }
  public override getClientID() {
    //  super.sClientID = "qs-" + this.Global_Variables.UUID;
    // return uuidv4();
    return "qs-" + this.Global_Variables.UUID;
  }

  constructor(
    private activatedRoute: ActivatedRoute,
    private oTBS: TitleBarService,
    public oRouter: Router,
    private oAPIService: APIService,
    private oAuthService: AuthService
  ) {
    //, private swUpdate: SwUpdate, private snackbar: MatSnackBar) {
    super(MQTT_HOST, MQTT_PORT, Global_Variables, false);

    if (Global_Variables.isMobile == false) {
      this.oRouter.navigate(["not_mobile"]);
      return;
    }
    if (Global_Variables.sCountry == "") {
      this.oRouter.navigate([""]);
      return;
    }

    this.sCountry = Global_Variables.sCountry;
    this.sTZ = Global_Variables.sTZ;
    console.info(this.sCountry);
    this.searchControl = new FormControl();
    this.load();
    this.oTBS.titleChange({ title: "Clinics", status: "" });
    this.sMqttHost = MQTT_HOST; //'m-ezq.ignorelist.com';
  }
  //  bActive = false;

  async showDetails(oEntity: any) {
    if (this.oEntity !== undefined && oEntity != this.oEntity) {
      this.oEntity.bExpanded = false;
    }
    oEntity.bExpanded = !oEntity.bExpanded;
    this.oEntity = oEntity;
    console.log(oEntity.bExpanded, this.oEntity.bExpanded);
    //  this.bActive = !this.bActive;
    Global_Variables.oEID = oEntity.ID;

    if (!this.oEntity.bExpanded) {
      try {
        super.setConnect(false);
        // super.disconnectMQTT();
      } catch (err) {
        //      console.log("ERROR ", err);
      }
    }
    if (this.oEntity.bExpanded) {
      this.oAPIService
        .send2ServerP("services/" + this.oEntity.ID + "/f", true)
        .then((data: any) => {
          this.oServices = data.result;
          // console.log(this.oServices);
          this.sMessage = null;
          //      console.log(this.oItems);
          if (this.oServices.length > 0) {
            // this.sMqttHost = this.oItems[0].MQTTHOST;
            super.setConnect(true);
            this.oServices.forEach((oItem, index) => {
              //          console.log("*** ", oItem);
              if (oItem._D.length > 0)
                oItem._D[0].QUEUEN_T = this.padLeft(oItem._D[0].QUEUEN, "0", 3);
              oItem.CURRENTQN_T = this.padLeft(oItem.CURRENTQN, "0", 3);
              oItem.ISSUEDQN_T = this.padLeft(oItem.ISSUEDQN, "0", 3);
              this.setCanQueueUpNow(oItem);
              if (index == this.oServices.length - 1) {
                setTimeout(() => {
                  this.bLoaded = true;
                }, 500);
              }
            });
            super.subscribe("CQ/" + Global_Variables.oEID + "/#");
          } else {
            super.setConnect(false);
            //        this.bShouldConnect = false;
            this.sMessage = NO_SERVICES_MESSAGE;
            this.bLoaded = true;
          }
        });
    }
  }

  bErrorLoading = false;
  async load() {
    this.bErrorLoading = false;

    // var b = await (this.oAuthService.loadUserInfo({}));
    //    console.log("Logged In " + b);
    let a = 1 ;
    this.oAPIService
      .send2ServerP("entities", true, { COUNTRY: Global_Variables.sCountry })
      .then((data:any) => {
        if (data) {
          this.oEntities = data.result;
          //        console.log(this.oItems);
        }
        this.bLoaded = true;
      })
      .catch(async (err: any) => {
        console.error(err);
        if (err.status == 403) {
          // token expired
          console.warn("JWT Expired.  Refresing");
          var data: any = {};
          // data.DEVICEUID = this.oAuthService.getDeviceID(); 
          data.email = this.oAuthService.getDeviceID(); 
          data.password = data.email; 
          console.log("DeviceUID: ", data.email);
          await this.oAuthService.login(Global_Variables.AUTH_URL, data, "entities");
          return;
        } else this.bErrorLoading = true;
        // this.swUpdate.available.subscribe(evt => {
        //   let snack = this.snackbar.open('Update Available', 'Reload Please.');

        //   snack.onAction().subscribe(() => {
        //     window.location.reload();
        //   });

        //   setTimeout(() => {
        //     snack.dismiss();
        //   }, 10000);
        // });
      });
  }

  // public onConnectionStatusChange(sMqttStatus) {
  //   this.oTBS.titleChange({ title: this.sTitle, status: sMqttStatus });
  // }
  public override getUserName() {
    //TODO get a new JWT token that expires in few minutes.
    return (Global_Variables.token?.['access_token']||'');
  }
  goToStatus() {
    // try {
    //   super.setConnect(false);
    // }
    // catch (err) { }
    this.oRouter.navigate(["queuestatus"]);
  }
  padLeft(text: any, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  }
  isClosedNow(oItem: any) {
    oItem.bClosed = true;
    oItem._BusinessHours.forEach((o: any) => {
      //      console.log(o);
      //get current time and check if it is inbetwen
     
      var currentTime = moment(o.LOCAL_TIME).format("Hmm");
      // console.log ( "TIMEZONE: " +  moment().parseZone().localeData ) ;

      var iNow = Number(currentTime);
      var iTO = Number(o.TIME_OPEN);
      var iTC = Number(o.TIME_CLOSE);
      // console.log("Comparing: " + currentTime + " with " + moment(o.TIME_OPEN).format("Hmm") + " and " + moment(o.TIME_CLOSE).format("Hmm"));
      // if (moment(currentTime).isBetween(moment(o.TIME_OPEN, "Hmm"), moment(o, "Hmm")))
      if (iNow >= iTO && iNow <= iTC) {
        oItem.TIME_OPEN = iTO;
        oItem.TIME_CLOSE = iTC;
        //     console.log("Open NOW...");
        oItem.bClosed = false;
      }
      oItem.bLoading = false;
    });
  }

  // onPurchase(oItem: any) {
  //   //https://medium.com/@andrew.thielcole/in-app-purchases-with-ionic-3-af13b21f49f2
  //   // ionic cordova plugin add cc.fovea.cordova.purchase --variable BILLING_KEY="<ANDROID_BILLING_KEY>"
  //   // $ npm install --save @ionic-native/in-app-purchase-2

  // }
  onBookAppointment(oItem: any) {
    console.log(oItem);
    //send oService
    this.oRouter.navigateByUrl("appointment", {
      state: { oServiceItem: oItem, oEntityItem: this.oEntity },
    });
    //   this.navCtrl.setRoot('AppointmentPage') ;
  }
  onGetQN(oItem: any) {
    //    this.oIAP.buy(this.products[0]).then(data => {
    /*
   data will contain {
     transactionId: ...
     receipt: ...
     signature: ...
   }
 */
    this.oAPIService
      .send2ServerP("gqn/" + Global_Variables.oEID + "/" + oItem.ID, true)
      .then((data: any) => {
        if (data._error) {
          console.error(data._error);
          return;
        }
        // Required for Android.  However, can be called for iOS as well.
        //      this.oIAP.consume ( data.productType, data.receipt, data.signature ) ;
        this.goToStatus();
      });
    //  });
  }
  setCanQueueUpNow(oItem: any) {
    oItem.bLoading = true;
    this.oAPIService
      .send2ServerP("q/opened/" + Global_Variables.oEID + "/" + oItem.ID)
      .then((data: any) => {
        //      console.log(JSON.stringify(data.result));
        if (data.result.length > 0) {
          //        this.zone.run(() => {
          oItem._bQOpened = true;
          oItem._BusinessHours = data.result;
          this.isClosedNow(oItem);
          //          console.log("Setting Opened = TRUE");
          //        });
        } else {
          oItem.bClosed = true;
          oItem.PREFIX = "";
          oItem.CURRENTQN_T = "closed";
          oItem.ISSUEDQN_T = "closed";
        }
      });
  }
  async onBusinessHours(oItem: any) {
    oItem.ENAME = this.oEntity.ENAME;
    await this.oRouter.navigateByUrl("businesshours", { state: oItem });
  }

  ngOnInit() {
    // this.sMessage = null;
    // if (this.bLoaded && this.oItems.length > 0) {
    //   // this.bShouldConnect = true;
    //   //      super.subscribe("CQ/" + Global_Variables.oEID + "/#");
    //   super.setConnect(true);
    //   this.oItems.forEach(oItem => {
    //     this.setCanQueueUpNow(oItem);
    //   })
    // }
    // else {
    //   this.sMessage = NO_SERVICES_MESSAGE;
    // }
  }
  ngOnDestroy() {
    try {
      super.setConnect(false);
      // super.disconnectMQTT();
    } catch (err) {
      //      console.log("ERROR ", err);
    }
  }

  processMessage(message: Paho.MQTT.Message) {
    // ./pushMessage.sh 'CQ/1/11' '21,49'
    //    var t = message.destinationName.split('/');
    //    console.log(d[0] + " " + d[1] + " " + d[2]);
    //    var m = message.payloadString.split(',');
    //    console.log(m[0] + " and " + m[1]);
    var t = super.getTopicPathInArray(message);
    var m = super.getMessageInArray(message);

    if (t[0] == "ServerUp") {
      return;
    }
    if (t[0] == "IP") {
      this.onConnectionStatusChange(m);
      return;
    }
    if (t[0] == "CQ") {
      this.oServices.forEach((oItem) => {
        if (oItem.ID == t[2]) {
          // if ( m[0] == '0' && m[1] == '0')
          //   return ;
          oItem.CURRENTQN = Number(m[0]);
          oItem.CURRENTQN_T = this.padLeft(oItem.CURRENTQN, "0", 3);
          oItem.ISSUEDQN = Number(m[1]);
          oItem.ISSUEDQN_T = this.padLeft(oItem.ISSUEDQN, "0", 3);
        }
      });
    }
  }
}
