<!-- <div class="container-fluid"> -->
<!-- <h2 class="mb-5 mt-4">Clinics</h2> -->
<!-- <div> -->
<!-- <div class="wrap"> -->
<div class="card" *ngFor="let oo of oEntities">
  <!-- <div class="card-header entity-title">
        </div> -->
  <div class="card-body">
    <div class="task">
      <div class="abstract" (click)="showDetails(oo)" class="entity-title">
        {{ oo.ENAME }}<br />{{ oo.ADDRESS }}
      </div>
      <div
        class="details service-title"
        [ngClass]="{
          expanded: oo?.bExpanded == true,
          collapsed: oo?.bExpanded == false
        }"
      >
        <!-- <div class="details__inner"> -->
        <div *ngFor="let o of oServices">
          <!-- <mat-divider></mat-divider> -->
          <!-- <mat-card-title class="service-title"> -->
          <div>
            <div>
              <span *ngIf="!o.bClosed" class="badge badge-success">open</span>
              <span *ngIf="o.bClosed" class="badge badge-danger">closed</span>
              {{ o.NAME }}
            </div>
            <div *ngIf="o.bClosed" text-center class="float-right">
              <!-- <div class="float-left">T</div>
                <div class="float-right"> -->
              <a color="accent" mat-raised-button (click)="onBusinessHours(o)"
                >Business Hours</a
              >
              <!-- </div> -->
            </div>
            <div *ngIf="!o.bClosed" text-center class="float-right">
              {{ o.TIME_OPEN | timeFormat }} -
              {{ o.TIME_CLOSE | timeFormat }}
            </div>
          </div>
          <div>
            <br />
            <br />
          </div>
          <div class="row">
            <div class="column">
              <span class="badge badge-warning">Now AAAA Servicing</span>
            </div>
            <div class="column">
              <span class="badge badge-warning">Last</span>
            </div>
          </div>
          <div class="row">
            <div class="column">
              <ng-container *ngIf="o.CURRENTQN_T != 'null'; else nullTemplate">
                {{ o.PREFIX | lpad: 2:" " }}{{ o.CURRENTQN_T }}
              </ng-container>
              <ng-template #nullTemplate>                
                <i>None</i>
              </ng-template>
              <!-- {{ o?.PREFIX | lpad: 2:" " }}{{ o.CURRENTQN_T }} -->
            </div>
            <div class="column">
              <ng-container *ngIf="o.ISSUEDQN_T; else nullTemplate">
                {{ o.PREFIX | lpad: 2:" " }}{{ o.ISSUEDQN_T }}
              </ng-container>
              <ng-template #nullTemplate>
                <i>None</i>
              </ng-template>
              <!-- {{ o?.PREFIX | lpad: 2:" " }}{{ o.ISSUEDQN_T }} -->
            </div>
          </div>
          <div class="row">
            <div class="column">
              <div *ngIf="!o.bClosed && o._D.length > 0">
                <!-- <span class="badge badge-warning" (click)="goToStatus()">Your Q# {{ o.PREFIX | lpad: 2:" " }}{{ o._D[0].QUEUEN_T }}</span> -->
                <a mat-raised-button color="accent" (click)="goToStatus()"
                  >Your Q# {{ o.PREFIX | lpad: 2:" " }}{{ o._D[0].QUEUEN_T }}</a
                >
              </div>
              <div *ngIf="!o.bClosed && o._D.length == 0">
                <a color="accent" mat-raised-button (click)="onGetQN(o)"
                  >Join Queue</a
                >
              </div>
              <div *ngIf="o.bClosed">
                Closed
              </div>
            </div>
            <!-- <div *ngIf="o._D.length > 0">
                      <div>
                        <a
                          mat-button
                          block
                          color="accent"
                          (click)="goToStatus()"
                          >Your Q# {{ o.PREFIX | lpad: 2:" "
                          }}{{ o._D[0].QUEUEN_T }}</a
                        >
                      </div>
                    </div> -->
            <div class="column">
              <a color="accent" mat-raised-button (click)="onBookAppointment(o)"
                >Appointment</a
              >
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>
    <!-- </div> -->
    <!-- <div class="card-body">
            <div class="task">
              <div class="abstract" (click)="showDetails(o)">
                <p>General Practice</p>
              </div>
              <div class="details" [ngClass]="{'expanded': bActive == true, 
          'collapsed': bActive == false}">
                <div class="details__inner">
                  <h3>Services</h3>
                  <p>Dental RTC</p>
                  <p>Dental Implant</p>
                  <p class="date">
                    <span>20</span>
                    <span>APR</span></p>
                  <a href="#" class="btn btn-primary">Go somewhere</a>
                </div>
              </div>
            </div>
          </div> -->
  </div>
  <!--         
        <div class="dashboard-cards">
  
          <div class="card">
            <div class="card-header">
              Featured
            </div>
            <div class="card-body">
              <h5 class="card-title">Special title treatment</h5>
              <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
              <a href="#" class="btn btn-primary">Go somewhere</a>
            </div>
          </div>
          <div class="card">
            <div class="card-header">
              Featured
            </div>
            <div class="card-body">
              <h5 class="card-title">Special title treatment</h5>
              <p class="card-text">With supporting text below as a natural lead-in to additional content.</p>
              <a href="#" class="btn btn-primary">Go somewhere</a>
            </div>
          </div>
          <div class="card" style="width: 18rem;">
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
                content.</p>
              <a href="#" class="card-link">Card link</a>
              <a href="#" class="card-link">Another link</a>
            </div>
          </div>
          <div class="card" style="width: 18rem;">
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
                content.</p>
              <a href="#" class="card-link">Card link</a>
              <a href="#" class="card-link">Another link</a>
            </div>
          </div>
          <div class="card" style="width: 18rem;">
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
                content.</p>
              <a href="#" class="card-link">Card link</a>
              <a href="#" class="card-link">Another link</a>
            </div>
          </div>
          <div class="card" style="width: 18rem;">
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
                content.</p>
              <a href="#" class="card-link">Card link</a>
              <a href="#" class="card-link">Another link</a>
            </div>
          </div>
          <div class="card" style="width: 18rem;">
            <div class="card-body">
              <h5 class="card-title">Card title</h5>
              <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
              <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's
                content.</p>
              <a href="#" class="card-link">Card link</a>
              <a href="#" class="card-link">Another link</a>
            </div>
          </div>
        </div>
      </div> -->
</div>
<!-- </div> -->
<!-- </div> -->
<!-- </div> -->
