import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../auth.service";
import { APIService } from "../api.service";
import { Global_Variables } from "../../app/properties";
//import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { UserLocation } from "../user.location";
import { GeoLocationService } from "../geo-location.service";
import { DeviceDetectorService } from 'ngx-device-detector';
import { v4 as uuidv4 } from 'uuid';
// import { LangPipe } from "../lang.pipe";
// import { DecimalPipe } from "../decimal.pipe";
// import { MinutesPipe } from "../minutes.pipe";
/*
Client IP => https://api.ipify.org/?format=json


*/

//var bSQLInjectionTest: boolean = false;
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  bLoaded = false;
  sError = "";
  lat: number = 43.653908;
  lng: number = -79.384293;
  //  dLat = 0; dLong = 0;
  sCountry = "NotSet";
  oDemographics: any = undefined;
  constructor(
    private activatedRoute: ActivatedRoute,
    private oRouter: Router,
    private oAPIService: APIService,
    private readonly oAuthService: AuthService,
    private oGeoLocationService: GeoLocationService
    , private deviceService: DeviceDetectorService
  ) {
    Global_Variables.sCountry = "";
  }

  bHasLocation = false;

  errMsg = "";
  sImage = "";
  viewType!: string;
  position!: UserLocation;
  errorType!: string;
  currentLang!: Object;
  async ngOnInit() {
    await this.isMobile();
    this.oDemographics = this.oAuthService.getDemographics();
    if (this.oDemographics !== undefined) {
      console.info(this.oDemographics);
      Global_Variables.sCountry = this.oDemographics.country_code;
      if (Global_Variables.sCountry !== undefined && Global_Variables.sCountry != "" ) {
        this.sCountry = Global_Variables.sCountry;
        // console.log("Country: ", Global_Variables.sCountry);
//        Global_Variables.sCountry = 'IN';
        this.bLoaded = true;
        console.log("ISMOBILE: ", Global_Variables.isMobile) ;
      }
    }
    if ( Global_Variables.isMobile )
    {
      this.showEntities();
    }
//    this.getLocation();
    //    https://api.bigdatacloud.net/data/client-info
    // if (Global_Variables.sCountry !== "") {
    //   this.bHasLocation = true;
    //   Global_Variables.sCountry = "IN";
    //   this.showEntities();
    //   return;
    // }

    // if (!bSQLInjectionTest) {
    //   // this.oGeoLocationService.location$.subscribe((pos: UserLocation) => {
    //   //   this.position = pos;
    //   //   console.log(this.position);
    //   // });

    //   // this.oGeoLocationService.errors$.subscribe((type: string) => {
    //   //   this.errorType = type;
    //   //   console.log(this.errorType);
    //   // });

    //   // // this.langService.currentTranslation$.subscribe(
    //   // //   (translation) => (this.currentLang = translation)
    //   // // );

    //   // this.oGeoLocationService.getLocation();
    //   console.log("Country: ", Global_Variables.sCountry);
    // } else {
    //   // Global_Variables.sCountry = "IN";
    //   // this.showEntities();
    //   // this.bLoaded = true;
    // }
    // setInterval(() => {
    //   this.oGeoLocationService.getLocation();
    // }, 10000);
  // }
  // onTypeChange(type) {
  //   this.viewType = type;
  }

  isMobileBrowser  () {
    var deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    console.log(deviceInfo);
    console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
    return !isDesktopDevice ;
  }
  async isMobile() {
    let me = this;
    await this.oAPIService
      .send2ServerNoHeader("https://api.bigdatacloud.net/data/client-info")
      .then((d: any) => {
        console.log(d);
        if (d.country_code !== undefined) {
          Global_Variables.sCountry = d.country_code;
          if ( d.isMobile == false )
            d.isMoble = this.isMobileBrowser() ;
          Global_Variables.isMobile = d.isMobile;
          console.log("Is Mobile: ", d.isMobile) ;
          // Global_Variables.isMobile = true ;// unless a solution is found
          me.sCountry = Global_Variables.sCountry;
          me.oAuthService.saveDemographics(d);
          console.log("Fetched Country: ", Global_Variables.sCountry);
          me.bLoaded = true;
        }
        else
          Global_Variables.isMobile = true ;// unless a solution is found
          this.getLocation();
      });
  }
  async getLocation() {
    var me = this;
    await this.oAPIService
      .send2ServerNoHeader("https://ipapi.co/json")
      .then((d: any) => {
        console.log("TEST", d);
        Global_Variables.sCountry = d.country_code;
        me.sCountry = Global_Variables.sCountry;
        this.oAuthService.saveDemographics(d);
        console.log("Fetched Country: ", Global_Variables.sCountry);
        me.bLoaded = true;
        this.showEntities() ;
      });
  }
  async getLocationGeoV2NotUsed() {
    //    this.sCountry = Global_Variables.sCountry;
    this.bHasLocation = true;
    // console.log(Global_Variables);
    // if (Global_Variables.sCountry == "") {
    //      this.sCountry = this.getLocation();
    this.getPosition()
      .then((pos) => {
        console.info(pos);
        this.lat = pos.lat;
        this.lng = pos.lng;
        let me = this;
        // https://developer.mapquest.com/documentation/geocoding-api/reverse/get/
        this.sImage = `https://www.mapquestapi.com/staticmap/v5/map?key=qkcwM3ZHOcX9HAWBhMB41WQhl7Wx8ib8&zoom=17&locations=${this.lat},${this.lng}`;
        let sURL = `https://www.mapquestapi.com/geocoding/v1/reverse?key=qkcwM3ZHOcX9HAWBhMB41WQhl7Wx8ib8&location=${this.lat},${this.lng}&outFormat=json&thumbMaps=false`;
        this.oAPIService
          .send2ServerNoHeader(sURL)
          .then((d:any) => {
            console.info(d.results[0].locations[0].adminArea1);
            Global_Variables.sCountry = d.results[0].locations[0].adminArea1;
            me.sCountry = Global_Variables.sCountry;
            me.oAuthService.saveDemographics({
              sCountry: Global_Variables.sCountry,
              lat: me.lat,
              lng: me.lng,
            });
            this.showEntities();
            this.bLoaded = true;
          })
          .catch((err: any) => {
            this.errMsg = err;
            console.error(err);
//            Global_Variables.sCountry = "IN";
            //              this.showEntities();
          });

        //        https://www.mapquestapi.com/staticmap/v5/map?key=qkcwM3ZHOcX9HAWBhMB41WQhl7Wx8ib8&center=New+York
        //   https://www.mapquestapi.com/staticmap/v5/map?key=qkcwM3ZHOcX9HAWBhMB41WQhl7Wx8ib8&locations=13.111546,80.293128&zoom=17
        //   https://www.mapquestapi.com/geocoding/v1/reverse?key=qkcwM3ZHOcX9HAWBhMB41WQhl7Wx8ib8&location=13.111546%2C80.293128&outFormat=json&thumbMaps=false
      })
      .catch((err) => {
        this.errMsg = err;
        console.error(err);
        //          this.showEntities();
        this.bLoaded = true;
      });
    // }
    // else {
    //   this.showEntities();
    //   //      (async () => { await this.checkInstantQ(); })();
    // }
    // console.log("***", this.activatedRoute.snapshot.queryParamMap.keys);
    // (async () => { await this.checkInstantQ(); })();
    // console.log("****QRParsing Finished");
    // if (!this.oAuthService.exists()) {
    //   var data: any = {};
    //   data.DEVICEUID = uuidv4();
    //   try {
    //     //        var res = await this.oAuthService.login("auth", data, "entities");
    //     var res = await this.oAuthService.login("auth", data, null);
    //     return;
    //   }
    //   catch (err) {
    //     console.log(err);
    //   }
    // }
    //    this.oRouter.navigate(['entities']);
  }
  // async checkInstantQ() {
  //   // http://d-ezq.ignorelist.com/?a75bc6ec-344a-006b-28b4-9e949866a485
  //   // http://localhost:8888/?a75bc6ec-344a-006b-28b4-9e949866a485
  //   console.log(this.activatedRoute);
  //   console.log(this.activatedRoute.snapshot.queryParamMap.keys);
  //   if (this.activatedRoute.snapshot.queryParamMap.keys !== undefined) {
  //     // if (this.activatedRoute.snapshot.queryParamMap.keys.length != 1)
  //     Global_Variables.InstantQ = this.activatedRoute.snapshot.queryParamMap.keys[0];
  //     // }
  //     // await (this.activatedRoute.queryParams.subscribe(params => {
  //     //   console.log("Params***", params);
  //     //   if (params['qr'] !== undefined)
  //     //     Global_Variables.InstantQ = params['qr'];
  //     console.log(Global_Variables.InstantQ);
  //     //for QR code
  //     if (Global_Variables.InstantQ !== undefined && Global_Variables.InstantQ.length > 2
  //       //&& Global_Variables.InstantQ.indexOf("ionic") == -1
  //     ) {
  //       this.getInstantQNumber();
  //     }
  //     else {
  //       this.showEntities();
  //       // this.searchControl = new FormControl();
  //       // this.load();
  //       // this.oTBS.titleChange({ title: 'Clinics', status: '' });
  //     }
  //     // }))
  //   }
  //   console.log("Out of checkInstantQ");
  // }

  // async getInstantQNumber() {
  //   var b = await (this.oAuthService.loadUserInfo({}));
  //   var sInstantQServiceID = Global_Variables.InstantQ; //.substr(1);
  //   Global_Variables.InstantQ = null;
  //   console.log(sInstantQServiceID);
  //   // console.log(this.sCountry);
  //   //Dispatch a Q# instantly
  //   this.oAPIService.send2ServerP("instantqn/" + sInstantQServiceID, true, { COUNTRY: Global_Variables.sCountry }).then((data: any) => {
  //     if (data._error) {
  //       if (data._error.indexOf("EX_Q_ALREADY_PRESENT") == -1) {
  //         console.log(data._error);
  //         this.oRouter.navigateByUrl('entities');
  //         //          this.load();
  //         return;
  //       }
  //     }
  //     console.log("queuestatus");
  //     this.oRouter.navigateByUrl('queuestatus');
  //   });
  // }

  async showEntities() {
    if (!this.oAuthService.exists()) {
      var data: any = {};
      data.DEVICEUID = this.oAuthService.getDeviceID();
      if (data.DEVICEUID === undefined || data.DEVICEUID == null)
      {
        data.DEVICEUID = this.oAuthService.saveDeviceID(uuidv4());
      }
      //RUST requires only email and password
      // data.email = this.oAuthService.getDeviceID();
      // if (data.email === undefined || data.email == null)
      // {
      //   data.email = this.oAuthService.saveDeviceID(uuidv4());
      // }
      // data.password = data.email ;
    try {
        console.log("Authenticating")
        await this.oAuthService.login("auth", data, "entities");
      } catch (err) {
        // var data: any = {};
        // data.DEVICEUID = uuidv4();
        // try {
        //   var res = await this.oAuthService.login("auth", data, "entities");
        //   // var res = await this.oAuthService.login("auth", data, null);
        //   return;
        // }
        console.log(err);
      }
      return ;
    }
    this.oRouter.navigate(["entities"]);
  }
  locationError(error:any) {
    console.log(error);
    switch (error.code) {
      case error.PERMISSION_DENIED:
        this.sError = "User denied the request for Geolocation.";
        break;
      case error.POSITION_UNAVAILABLE:
        this.sError = "Location information is unavailable.";
        break;
      case error.TIMEOUT:
        this.sError = "The request to get user location timed out.";
        break;
      case error.UNKNOWN_ERROR:
        this.sError = "An unknown error occurred.";
        break;
      default:
        this.sError = "???";
    }
  }
  fromOptions(options:any) {
    return {
      enableHighAccuracy: options.enableHighAccuracy,
      timeout: undefined, //options.timeout._0 || Infinity,
      maximumAge: options.maximumAge._0 || 0,
    };
  }
  //function that gets the location and returns it
  getPosition(): Promise<any> {
    if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          (resp) => {
            resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
          },
          (err) => {
            reject(err);
          }
        );
      });
    }
    console.log("FATAL getPosition failed.  navigator.geolocation is not persent, returing 0, 0")
    return new Promise((resolve) => { resolve({ lng: 0, lat: 0 }); })
  }

  // getLocation(): string {
  //   Global_Variables.sCountry = 'IN';
  //   return Global_Variables.sCountry;
  //   // this.oAPIService.send2Server("https://ifconfig.co/country-iso").then((s) => {
  //   //   console.log(s);
  //   //   this.sCountry = s;
  //   //   Global_Variables.sCountry = this.sCountry;
  //   //   this.bLoaded = true;
  //   // })
  // }
  //function that retrieves the position
  // showPosition(position) {
  //   var me = this;

  //   console.log(position);
  //   me.dLat = position.coords.latitude;
  //   me.dLong = position.coords.longitude;
  //   const url = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}&localityLanguage=en`;
  //   console.log(url);
  //   me.oAPIService.send2Server(url).then((s) => {
  //     console.log(s.countryCode);
  //     me.sCountry = s.countryCode;
  //     Global_Variables.sCountry = s.countryCode;
  //     Global_Variables.sCity = s.city;
  //     this.bLoaded = true;
  //     // Global_Variables.sCountry = 'IN';
  //     // this.oRouter.navigate(['entities']);
  //     var location = {
  //       longitude: position.coords.longitude,
  //       latitude: position.coords.latitude
  //     }
  //     console.log(location)
  //   });

  // }
}
