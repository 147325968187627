import { CommonModule } from "@angular/common";
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MaterialModule } from "./material.module";
import { MatGridListModule } from "@angular/material/grid-list";
import { HttpClientModule } from "@angular/common/http";
import { StorageServiceModule } from "ngx-webstorage-service";

import { LoginComponent } from "./login/login.component";
import { EntitiesComponent } from "./entities/entities.component";
import { ServicesComponent } from "./services/services.component";
import { NgPipesModule } from "ngx-pipes";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { BusinessHoursComponent } from "./business-hours/business-hours.component";
import { PipesModule } from "./pipes/pipes.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import { QueueStatusComponent } from "./queue-status/queue-status.component";
import { MyAppointmentsComponent } from "./my-appointments/my-appointments.component";
import { AppointmentComponent } from "./appointment/appointment.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
// import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

// import { MaterialDesignFrameworkModule } from '@ajsf/material';
// import { Bootstrap4FrameworkModule } from '@ajsf/bootstrap4' ;

import { MatCardModule } from "@angular/material/card";
import { MatSidenavModule } from "@angular/material/sidenav" ;
import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { MatDividerModule } from '@angular/material/divider';
// import { DynamicFormBuilderModule } from "./dynamic-form-builder/dynamic-form-builder.module";

import { ReachUsComponent } from "./reach-us/reach-us.component";
import { Time24to12FormatPipe } from "./time24to12-format.pipe";

// import { AgmCoreModule } from "@agm/core";
import { TakeQueueComponent } from "./take-queue/take-queue.component";
import { ClinicsComponent } from "./clinics/clinics.component";
import { DecimalPipe } from "./decimal.pipe";
import { MinutesPipe } from "./minutes.pipe";
import { LangPipe } from "./lang.pipe";
// import { InputTextModule } from 'primeng/inputtext';
// import { InputTextareaModule } from 'primeng/inputtextarea';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EntitiesComponent,
    ServicesComponent,
    BusinessHoursComponent,
    QueueStatusComponent,
    AppointmentComponent,
    MyAppointmentsComponent,
    ContactUsComponent,
    ReachUsComponent,
    TakeQueueComponent,
    ClinicsComponent,
    Time24to12FormatPipe,
    DecimalPipe,
    MinutesPipe,
    LangPipe,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    StorageServiceModule,
    // MaterialDesignFrameworkModule,
    // Bootstrap4FrameworkModule,
    NgPipesModule,
    // NoopAnimationsModule,
    BrowserAnimationsModule,
    PipesModule,
    FlexLayoutModule,
    MatCardModule,
    MatGridListModule,
    MatSidenavModule,
    MatDividerModule,

    // InputTextModule,
    // InputTextareaModule,
    // DynamicFormBuilderModule,
    // AgmCoreModule.forRoot({
    //   apiKey: "AIzaSyCM1xTx1C2EtwH3WR2zlvbBW8f-eg9sV20",
    // }),
    // MatProgressSpinnerModule,
    //    BrowserAnimationsModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }], //{ provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule {}
