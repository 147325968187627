import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleBarService {

  private oTitleObject = new BehaviorSubject({ title: 'EzQueue', status: '' });
  oCurrentTitle = this.oTitleObject.asObservable();

  constructor() { }

  titleChange(obj:any) {
    this.oTitleObject.next(obj);
  }
}
