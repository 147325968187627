<mat-toolbar color="accent">
    <mat-icon *ngIf="isMobile" mat-icon-button (click)="sidenav.toggle()">menu</mat-icon>
  <!-- &#9776; <mat-icon></mat-icon> -->
  <!-- <button color="accent" href="#menu" (click)="sidenav.toggle()">
    <mat-icon>menu</mat-icon>
  </button> -->
  <!-- <button href="#menu" (click)="sidenav.toggle()" class="icon">
  </button> -->
  <h1>{{ sTitle }}</h1>
  <span class="menu-spacer"></span>
  <span class="connection-status">{{ sMqttStatus }}</span>
</mat-toolbar>
<mat-sidenav-container>
  <mat-sidenav #sidenav>
     <!-- mode="side" role="navigation" opened="false"> -->
    <mat-nav-list>
      <a mat-list-item [routerLink]="'/entities'" (click)="sidenav.close()">
        <!-- <mat-icon>face</mat-icon> -->
        <span class="nav-caption">Clinics</span>
      </a>
      <!-- <a mat-list-item [routerLink]="'/clinics'" (click)="sidenav.close()">
        <span class="nav-caption"> Clinics New</span>
      </a> -->
      <a mat-list-item [routerLink]="'/queuestatus'" (click)="sidenav.close()">
        <span class="nav-caption">Q Status</span>
      </a>
      <a
        mat-list-item
        [routerLink]="'/myappointments'"
        (click)="sidenav.close()"
      >
        <span class="nav-caption">My Appointments</span>
      </a>
      <a mat-list-item [routerLink]="'/contactus'" (click)="sidenav.close()">
        <span class="nav-caption">Contact Us</span>
      </a>
      <!-- <a mat-list-item [routerLink]="'/reachus'" (click)="sidenav.close()">
        <span class="nav-caption">Reach Us</span>
      </a> -->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div style="height: 100%;">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
