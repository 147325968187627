<div class="col-sm-12">
  <div class="card">
    <div class="card-header">Reach Us</div>
    <div class="card-body">
      <dynamic-form-builder [fields]="getFields()"></dynamic-form-builder>
    </div>
  </div>
</div>

<div class="col-sm-12">
  <div class="form-group" [formGroup]="form">
    <label>JSON</label>
    <textarea formControlName="fields" class="form-control" rows="3"></textarea>
  </div>
</div>

<div>
  {{fields | json }}
</div>
