<div *ngIf="bLoaded" class="full-height">
  <div *ngIf="oItems.length == 0 && oTodaysAppointment.length == 0">
    <p class="centered-message">You don't have any Appointments.</p>
  </div>
  <div *ngIf="oTodaysAppointment.length > 0">Today's Appointments</div>
  <mat-card appearance="outlined" class="custom" *ngFor="let o of oTodaysAppointment">
    <mat-card-title>
      <div class="entity-title">
        {{o.ENAME}}<br>{{o.ADDRESS}}
      </div>
    </mat-card-title>
    <mat-card-content>
      <mat-grid-list cols="2" rowHeight="4:1">
        <mat-grid-tile>Your Apt#</mat-grid-tile>
        <mat-grid-tile>Current #</mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="2" rowHeight="4:1">
        <!-- TODO Check with Time -->
        <!-- <div text-center *ngIf="o.CURRENTAQN < o.AQNUMBER" class="ksd-mq">{{o.AQNUMBER}}
            </div>
            <div text-center *ngIf="o.CURRENTAQN > o.AQNUMBER" class="ksd-mq-missed">{{o.AQNUMBER}}
            </div> -->
        <!-- <div *ngIf="o.CURRENTAQN == o.AQNUMBER; else elseBlock"><div class="ksd-mq-calling">{{o.AQNUMBER}}</div></div>
            <ng-template #elseBlock><div class="ksd-mq">{{o.AQNUMBER}}</div>></ng-template> -->
        <mat-grid-tile *ngIf="o.CURRENTAQN == o.AQNUMBER" class="ksd-mq-calling">{{o.AQNUMBER}}</mat-grid-tile>
        <mat-grid-tile *ngIf="o.CURRENTAQN != o.AQNUMBER" class="ksd-mq">{{o.AQNUMBER}}</mat-grid-tile>
        <mat-grid-tile class="ksd-cq">{{o.CURRENTAQN}}{{o.moved}}</mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="1" rowHeight="4:1" *ngIf="o.CURRENTAQN != o.AQNUMBER">
        <mat-grid-tile><a color="accent" mat-raised-button (click)="onCancelAppointment(o)">Cancel Appointment</a>
        </mat-grid-tile>
      </mat-grid-list>
      <!-- TODO once a new Queue number is called strike thru the Current # -->
      <div *ngIf="o.moved == '**'">** Another number called.</div>
    </mat-card-content>
  </mat-card>
  <mat-divider></mat-divider>
  <div *ngIf="oItems.length > 0">Appointments</div>
  <mat-card appearance="outlined" *ngFor="let o of oItems">
    <mat-card-title>
      <div class="entity-title">{{o.ENAME }}<br>{{o.SNAME}}</div>
    </mat-card-title>
    <mat-card-content>
      <mat-grid-list cols="1" rowHeight="8:1">
        <mat-grid-tile>On {{o.ADATE  | date:'mediumDate' }} @ {{o.AQNUMBER | time24to12Format }}</mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="1" rowHeight="8:1">
        <mat-grid-tile><a color="accent" mat-raised-button (click)="onCancelAppointment(o)">Cancel Appointment</a>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>
  </mat-card>
</div>
