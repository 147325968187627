import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { APIService } from '../api.service';
import { TitleBarService } from '../title-bar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  bMessageSent = false;

  private _jsonURL = 'assets/ContactUs.json';

  public onConnectionStatusChange(sMqttStatus:any) {
    this.oTBS.titleChange({ title: "Contact Us", status: sMqttStatus });
  }

  // formData: FormGroup;

  constructor(private formBuilder: FormBuilder, private oTBS: TitleBarService, public oRouter: Router, private readonly oAuthService: AuthService, private readonly oAPI: APIService) {
    this.onConnectionStatusChange('');

    // constructor(private formBuilder: FormBuilder) { }
  
    // ngOnInit(): void {
      // Parse the JSON schema and generate form controls dynamically
    }
  
    onSubmit() {
      // Handle form submission
      if (this.form.valid) {
        console.log('Form submitted successfully:', this.form.value);
        this.oAPI.send2ServerP("contactus/add", true, this.form.value).then((data: any) => {
          // var oItem = data.result[0];
          this.bMessageSent = true;
        });
    
        // Implement your form submission logic here
      } else {
        console.log('Form is invalid');
        // Optionally, display error messages or handle invalid form
      }
    }
  

  oErrorMessage = "";
  oFormSchema:any = {};
  oFormLayout:any = {};
  oFormData:any = {};
  form!: FormGroup;

  async ngOnInit() {
    this.form = this.formBuilder.group({
      NAME: ['', Validators.required],
      CONTACT: ['', Validators.required],
      PHONE: [''],
      EMAIL: ['', [Validators.required, Validators.pattern(/^\S+@\S+$/)]],
      MESSAGE: ['', Validators.required]
    });
  }
  // async ngOnInit() {
  //   this.oAPI.send2Server(this._jsonURL).then((content: string) => {
  //     var formObject:any = content; //JSON.parse(content) ;
  //     console.log(content)
  //     // this.formGroup = this.oFG.generateFormGroup(content);
  //     this.oFormSchema = formObject['schema'];
  //     this.oFormLayout = formObject['layout'];
  //     this.oFormData = formObject['data'];
  //   }).catch((error: any) => console.log(error));

  // }

  // onFormSubmit(formData: any) {
  //   this.oErrorMessage = "";
  //   console.log(formData);
  //   this.oAPI.send2ServerP("contactus/add", true, formData).then((data: any) => {
  //     // var oItem = data.result[0];
  //     this.bMessageSent = true;
  //   });
  // }
  // isFormValid(form: any) {
  //   return true;
  // }
}
