import { Injectable } from '@angular/core';
//import { Router, CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { v4 as uuidv4 } from 'uuid';
import { Global_Variables } from './properties';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(public authService: AuthService, public router: Router) { }
  async canActivate() {
    if (!await this.authService.checkAuthenticated()) {
      // this.router.navigate(['']);
      var data: any = {};
      data.DEVICEUID = uuidv4();
      // data.email = uuidv4();
      // data.password = data.email;
      this.authService.login(Global_Variables.AUTH_URL, data, 'entities')
      return false;
    }
    return true;
  }
}
