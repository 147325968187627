import { Pipe, PipeTransform } from '@angular/core';
// import {  } from "rxjs/util/isNumeric";
//import * as rxjsNumeric from "rxjs/util/isNumeric"

@Pipe({
  name: 'hoursMinutesSeconds'
})
export class HoursMinutesSecondsPipe implements PipeTransform {

  transform(value: any, ...args: any[]): string {
    // if ( isNumeric ( value ) == false )
    //   return value ;
    value = +value ;
    let minutes = Math.floor(value / 60);
    let hours = Math.floor(minutes / 60);
    let seconds = Math.floor(value % 60);
    minutes = Math.floor ( minutes % 60) ;
    if (hours > 0)
      return hours + "h " + minutes + "m " + seconds + "s";
    else
      return minutes + "m " + seconds + "s";
  }
}
