import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    var i = Number(value) ;
    var ampm = ( i >= 1200 ? 'pm' : 'am') ;
    i = ( i >= 1300 ? i - 1200 : i ) ;
    var s = i/100 | 0 ;    
    var n = (i%100) <= 9 ? '0' + i%100 : i%100 ;
    var a = ((s <= 9) ? ('      ' + s) : s) ;
    return a + ":" + n + " " + ampm;
  }
}
