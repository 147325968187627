import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SwPush, SwUpdate } from '@angular/service-worker';
import { WebNotificationService } from './web-notification.service';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer, Meta } from "@angular/platform-browser";
// import { Global_Variables } from '../app/properties';
// import { v4 as uuidv4 } from 'uuid';
import { TitleBarService } from '../app/title-bar.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Global_Variables } from "../app/properties";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  sTitle = 'qmobile';
  sMqttStatus = '';
  isEnabled = this.swPush.isEnabled;
  isGranted = Notification.permission === 'granted';
  constructor(private oTBS: TitleBarService,
    private activatedRoute: ActivatedRoute,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private metaTagService: Meta,
    private router: Router, private swPush: SwPush, private swUpdate: SwUpdate,
    private oWebNotificationService: WebNotificationService
    , private deviceService: DeviceDetectorService
  ) {
    // this.swPush.notificationClicks.subscribe( event => {
    //   console.log('Received notification: ', event);
    //   const url = event.notification.data.url;
    //   window.open(url, '_blank');
    // });
    this.isMobileBrowser() ;
    this.matIconRegistry.addSvgIcon(
      "ksd-left",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/arrow-circle-left.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "ksd-right",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/arrow-circle-right.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "ksd-exchange",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/exchange.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "ksd-menu",
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/img/menu.svg")
    );
    // console.log("***", this.activatedRoute.snapshot.queryParamMap.get('qr'));

    // console.log(activatedRoute.snapshot.queryParamMap);
    this.router.navigate(['login']);
  }
  isMobile = false ;
  isMobileBrowser  () {
    var deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    console.log(deviceInfo);
    console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
    this.isMobile = !isDesktopDevice ;
    this.isMobile = true ;
    Global_Variables.isMobile = this.isMobile;
    //return !isMobile ; // Not working
    //return true ;
  //   if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
  //       return true
  //  }
  //  else{
  //       return false
  //   }
  }

  // getBackgroundColor(status) {
  //   console.log(status);
  //   status = this.sMqttStatus;
  //   this.sColor = "white";
  //   if (status == 'connected')
  //     this.sColor = "lightgreen";
  //   if (status == 'NOT connected')
  //     this.sColor = "red";
  //   if (status == 'Not connected')
  //     this.sColor = "blue";
  //   console.log(this.sColor);
  //   return this.sColor;
  // }

  // ngAfterViewInit() {
  //   window.location.reload();
  // }

  // sColor = "red";
  ngOnInit() {
    // https://www.positronx.io/angular-seo-set-dynamic-page-title-meta-tags-in-universal-app/
    this.metaTagService.addTags([
      { name: 'keywords', content: 'Remote Queue, ezQ, ezQueue, Clinic' },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Walter Gunasekaran' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'date', content: '2020-06-31', scheme: 'YYYY-MM-DD' },
      { charset: 'UTF-8' }
    ]);
    this.oTBS.oCurrentTitle.subscribe(obj => {
      setTimeout(() => {
        this.sTitle = obj.title;
        this.sMqttStatus = obj.status;
        // console.log(this.sColor, this.sMqttStatus);
      }, 1);
    });
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(() => {
        console.log("New Version Available.");
        if (confirm("New version available. Load it ?")) {
          window.location.reload();
        }
      })
    }
    // http://d-ezq.ignorelist.com/?a75bc6ec-344a-006b-28b4-9e949866a485
    // http://localhost:8888/?a75bc6ec-344a-006b-28b4-9e949866a485
    //    http://localhost:8888/?ff=a75bc6ec-344a-006b-28b4-9e949866a485

    //    this.activatedRoute.queryParams.
    console.log(this.activatedRoute.snapshot);
    this.activatedRoute.queryParams.subscribe((parameter:any) => {
      console.log("^^^^^^^", parameter);
      console.log("^^^^^^^", parameter.parameter);
    })
    // console.log(this.activatedRoute);
    // console.log(this.activatedRoute.snapshot.queryParamMap.keys);

  }
  //   submitNotification(): void {
  // //    this.oWebNotificationService.subscribeToNotification();
  //   }

}
