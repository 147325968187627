import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Global_Variables } from "../../app/properties";
// import { MQTT_HOST, MQTT_PORT, MQTT_USESSL } from '../../app/env.properties';

// import { LoginServiceProvider } from '../../providers/login-service/login-service';
// import { ApiServiceProvider } from '../../providers/api-service/api-service';
import { APIService } from "../api.service";
// import { Paho } from 'mosq-mqtt/mqttws31';
// import { MqttService } from 'abstract-mqtt';
//import { LeftPadPipe } from 'angular-pipes/src/string/left-pad.pipe';
// import * as moment from 'moment';
// import { Location } from '@angular/common';

import { FormControl } from "@angular/forms";
//import 'rxjs/add/operator/debounceTime';
//import { sDOWNLOAD_URL } from '../../app/properties';
import { AuthService } from "../auth.service";
import { TitleBarService } from "../title-bar.service";
// import { SwUpdate } from '@angular/service-worker';
// import { MatSnackBar } from '@angular/material/snack-bar';

var bTestIndia: boolean = false;

@Component({
  selector: "app-entities",
  templateUrl: "./entities.component.html",
  styleUrls: ["./entities.component.scss"],
})
export class EntitiesComponent implements OnInit {
  ngOnInit(): void {}

  searchControl!: FormControl;
  bSearching: any;
  bLoaded = false;
  sCountry: any = "None";
  sTZ: any = "TZ";

  oItems: any[] = [];
  constructor(
    private activatedRoute: ActivatedRoute,
    private oTBS: TitleBarService,
    public oRouter: Router,
    private oAPIService: APIService,
    private oAuthService: AuthService
  ) {
    //, private swUpdate: SwUpdate, private snackbar: MatSnackBar) {
    // constructor(public navCtrl: NavController,
    // private modalCtrl: ModalController,
    // public oLoginService: LoginServiceProvider,
    // public oAPIService: ApiServiceProvider) {

    //    console.log(Global_Variables);
    if (Global_Variables.sCountry == "") {
      this.oRouter.navigate([""]);
      return;
    }

    if (bTestIndia) Global_Variables.sCountry = "IN";

    this.sCountry = Global_Variables.sCountry;

    this.sTZ = Global_Variables.sTZ;
    console.info(this.sCountry);
    this.searchControl = new FormControl();
    this.load();
    this.oTBS.titleChange({ title: "Clinics", status: "" });

    //    (async () => { await this.checkInstantQ(); })();
    // this.oAuthService.storeDeviceRegistrationInfo();

    // (async => {
    //   (this.activatedRoute.queryParams.subscribe(params => {
    //     console.log("Params***", params);
    //     if (params['qr'] !== undefined)
    //       Global_Variables.InstantQ = params['qr'];
    //     console.log(Global_Variables.InstantQ);
    //     //for QR code
    //     if (Global_Variables.InstantQ !== null && Global_Variables.InstantQ.length > 2
    //       //&& Global_Variables.InstantQ.indexOf("ionic") == -1
    //     ) {
    //       this.getInstantQNumber();
    //     }
    //     else {
    //       this.searchControl = new FormControl();
    //       this.load();
    //     }
    //   }))
    // })();
  }

  //request for location
  bErrorLoading = false;
  async load() {
    this.bErrorLoading = false;

    // console.log("DEVICEUID", this.oAuthService.getDeviceID());

    // var b = await (this.oAuthService.loadUserInfo({}));
    //    console.log("Logged In " + b);

    this.oAPIService
      .send2ServerP("entities", true, { COUNTRY: Global_Variables.sCountry })
      .then((data:any) => {
        if (data) {
          this.oItems = data.result;
                 console.log(this.oItems);
        }
        this.bLoaded = true;
      })
      .catch(async (err: any) => {
        console.error(err);
        if (err.status == 403) {
          // token expired
          console.warn("JWT Expired.  Refresing");
          var data: any = {};
          // data.DEVICEUID = this.oAuthService.getDeviceID();
          data.email = this.oAuthService.getDeviceID();
          data.password = data.email;
          // console.log("Sending DEVICEUID", this.oAuthService.getDeviceID());
          await this.oAuthService.login(Global_Variables.AUTH_URL, data, "entities");
          return;
        } else this.bErrorLoading = true;
        // this.swUpdate.available.subscribe(evt => {
        //   let snack = this.snackbar.open('Update Available', 'Reload Please.');

        //   snack.onAction().subscribe(() => {
        //     window.location.reload();
        //   });

        //   setTimeout(() => {
        //     snack.dismiss();
        //   }, 10000);
        // });
      });
  }
  // ionViewWillEnter() {
  //   // this.load();
  // }

  async onEntityClick(oItem: any) {
    Global_Variables.oEID = oItem.ID;
    await this.oRouter.navigateByUrl("services", { state: oItem });
  }
  // async onBusinessHours(oItem: any) {
  //   await this.oRouter.navigateByUrl('businesshours', { state: oItem });
  // }

  //  sImageFile = "assets/img/sm/" ;
  //sUrl = sDOWNLOAD_URL; //+ "../download/uat/yq.apk" ;
  // regularShare(){
  //   var msg = "Download ezQ App\n\n"  + sDOWNLOAD_URL + "\n";
  //   this.oSS.share(msg, null, this.sUrl);
  // }
  // regularShare() {
  //   var msg =
  //     "Visiting Clinic ? Take Queue # using your Smart Phone.  Check out ezQApp .";
  //   //    this.oSS.share(msg, 'ezQ App', null, sDOWNLOAD_URL);
  //   //    this.oSS.share(sDOWNLOAD_URL, null, null) ;
  //   //    this.oSS.shareViaWhatsApp(msg, null, sDOWNLOAD_URL);
  // }
  // //   onBookAppointment(oItem:any ) {
  //     this.navCtrl.push('AppointmentPage', { PEntity: oItem });
  // //    this.navCtrl.setRoot('AppointmentPage') ;
  //   }

  //   whatsappShare() {
  //     this.oSS.shareViaWhatsApp('Message via WhatsApp',
  //     null /* img */,
  //     "http://pointdeveloper.com/" /* url */,
  //     null,
  //     function (errormsg) { alert("Error: Cannot Share") }
  //   );
  // };
}
