import { Component, OnInit, OnDestroy } from "@angular/core";
//import { sIMAGE_URL } from '../../app/properties';
//import { Subscription } from "rxjs";
import * as moment from "moment";
import { MqttService } from "abstract-mqtt";
import { Router } from "@angular/router";
import { Global_Variables } from "../../app/properties";
import { APIService } from "../api.service";
import { Location } from "@angular/common";
import { Paho } from "mosq-mqtt/mqttws31";
import { MQTT_HOST, MQTT_PORT, MQTT_USESSL } from "../../env.properties";
//import { MatGridList, MatGridTile } from "@angular/material/grid-list";
import { TitleBarService } from "../title-bar.service";
//import { UUID } from "angular2-uuid";
import { v4 as uuidv4 } from 'uuid';

const bDebug = true;

@Component({
  selector: "app-queue-status",
  templateUrl: "./queue-status.component.html",
  // templateUrl: './test.html',
  styleUrls: ["./queue-status.component.scss"],
})
export class QueueStatusComponent
  extends MqttService
  implements OnInit, OnDestroy {
  sTitle = "Queue Status";
  //const REQUEUE_TEXT = 'Requeue' ;
  //const REQUEING_TEXT = 'Processing..' ;

  oOptions: any = {
    useSSL: MQTT_USESSL,
    userName: "test",
    password: "test12",
    //    reconnect: true
  };

  //  oEntity: any = {};
  oItems: any[] = [];
  bDisabled = true;
  bLoaded: boolean = false;
  bShowWaitTime = false;
  //  sRequeueText = REQUEUE_TEXT ;
  // private onResumeSubscription: Subscription;
  // ngOnDestroy() {
  //   // always unsubscribe your subscriptions to prevent leaks
  //   this.onResumeSubscription.unsubscribe();
  // }
  sMqttHost = MQTT_HOST;
  public override getMqttHost() {
    return this.sMqttHost;
  }

  public override getClientID() {
    //  super.sClientID = "qs-" + this.Global_Variables.UUID;
    return uuidv4();
  }
  constructor(
    private oTBS: TitleBarService,
    public oLocation: Location,
    public oRouter: Router,
    public oAPIService: APIService
  ) {
    super(MQTT_HOST, MQTT_PORT, Global_Variables, false);
    super.setMqttOptions(this.oOptions);
    this.sMqttHost = MQTT_HOST; //'m-ezq.ignorelist.com';
    this.oTBS.titleChange({ title: this.sTitle, status: super.getMqttStatus() });
    // this.Global_Variables.UUID = undefined;
    // try {
    //   this.oAPIService.send2ServerP("mqtthost/" + Global_Variables.oEID, true).then((data: any) => {
    //     var o = data.result;
    //     console.log(o);
    //     if (o.length > 0) {
    //       this.sMqttHost = o[0].MQTTHOST;
    //       if (this.oItems.length > 0)
    //         super.setConnect(true);
    //       if (bDebug)
    //         console.log(this.sMqttHost);
    //     }
    //     else
    //       this.sMqttHost = 'm-ezq.ignorelist.com';
    //   });
    // }
    // catch (err) {
    //   console.error(err);
    //   this.sMqttHost = 'm-ezq.ignorelist.com';
    // }
    //    this.oEntity = this.oLocation.getState();
    //     this.onResumeSubscription = platform.resume.subscribe(() => {
    // //      this.connect2MQTT();
    //       this.subscribe("CQ/" + this.oItems[0].ENTITYID + "/#");
    //     });

    // this.oAPIService.send2ServerP("mq", true).then((data: any) => {
    //   this.oItems = data.result;
    //   this.bLoaded = true;
    //   if (this.oItems.length > 0) {
    //     /*
    //       Topics:
    //         Current Queue ( for all services )
    //           CQ/<EntityID>/#
    //     */
    //     this.subscribe("CQ/" + this.oItems[0].ENTITYID + "/#");
    //   }
    // });
  }
  public override getUserName() {
    //TODO get a new JWT token that expires in few minutes.
    return Global_Variables.token?.['access_token']|| '';
  }

  //  ionViewDidEnter() {
  ngOnInit() {
    this.bLoaded = false;
    // this.setConnect(false);
    this.setConnect(true);
    // this.reconnect();
    try {
      this.oAPIService.send2ServerP("mq", true).then((data: any) => {
        this.oItems = data.result;
        if (this.oItems.length > 0) {
          this.oItems.forEach((oItem, index) => {
            console.log(oItem);
            oItem.QUEUEN_T = this.padLeft(oItem.QUEUEN, "0", 3);
            oItem.CURRENTQN_T = this.padLeft(oItem.CURRENTQN, "0", 3);
            oItem.LASTQ_T = this.padLeft(oItem.ISSUEDQN, "0", 3);
            this.setCanQueueUpNow(oItem);
            if (index == this.oItems.length - 1) {
              setTimeout(() => {
                this.bLoaded = true;
              }, 500);
            }
          });
          this.setConnect(true);
          // this.reconnect();
          this.oItems.forEach((oItem, index) => {
            //          this.subscribe("CQ/" + this.oItems[0].ENTITYID + "/" + oItem.SERVICEID);
            this.subscribe("CQ/" + oItem.ENTITYID + "/" + oItem.SERVICEID);
            this.showFreeSlots(oItem, 0);
          });
        } else {
          this.bLoaded = true;
        }
      });
    } catch (err) {
      console.error(err);
    }
  }
  delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  public override onConnectionStatusChange(sMqttStatus: any) {
    this.oTBS.titleChange({ title: this.sTitle, status: sMqttStatus });
  }
  ngOnDestroy() {
    try {
      super.setConnect(false);
      // super.disconnectMQTT();
      console.log("Disconnected")
    } catch (err) {
      //console.log(err);
    }
  }

  padLeft(text: any, padChar: string, size: number): string {
    return (String(padChar).repeat(size) + text).substr(size * -1, size);
  }
  processMessage(message: Paho.MQTT.Message) {
    // ./pushMessage.sh 'CQ/1/11' '23,<LastQ>'
    var t = super.getTopicPathInArray(message);
    if (t[0] == "ServerUp") {
      return;
    }
    if (t[0] == "IP") {
      this.onConnectionStatusChange(m);
      return;
    }
    var m = super.getMessageInArray(message);
    console.log(m)
    if (t[0] == "CQ") {
      this.oItems.forEach((oItem) => {
        // console.log(oItem);
        if (oItem.ENTITYID == t[1] && oItem.SERVICEID == t[2]) {
          oItem.CURRENTQN = Number(m[0]);
          oItem.CURRENTQN_T = this.padLeft(oItem.CURRENTQN, "0", 3);
          //          oItem.LASTQ = m[1] ;
          oItem.LASTQ_T = this.padLeft(m[1], "0", 3);
          oItem.bCanRequeue = Number(m[1]) > Number(oItem.QUEUEN) + 2;
          if (oItem.CURRENTQN >= Number(oItem.QUEUEN))
            oItem.bCanRequeue = false;
          //TO ENABLE LATER
          if (this.bShowWaitTime) this.onGetWaitTime(oItem);
        }
      });
    }
    // if (t[0] == 'AQ') { // Appointment
    //   this.oItems.forEach((oItem) => {
    //     if (oItem.ENTITYID == t[1] && oItem.SERVICEID == t[2]) {
    //       oItem.CURRENTAQN = Number(m[0]);
    //       oItem.CURRENTAQN_T = oItem.CURRENTAQN ;
    //     }
    //   });
    // }
  }

  showFreeSlots(oItem: any, iDir:any) {
    oItem.bShowFreeSlots = false;
    oItem.oFreeSlots = [];
    if (oItem.bClosed) return;
    if (oItem.UsersInQ <= 1) return;

    var sAPI = "freeslotsfront/";
    if (iDir == 1) sAPI = "freeslotsback/";
    this.oAPIService
      .send2ServerP(
        sAPI +
          oItem.ENTITYID +
          "/" +
          oItem.SERVICEID +
          "/" +
          oItem.QUEUEN +
          "/" +
          oItem.CURRENTQN,
        true,
        { DIR: iDir }
      )
      .then((data: any) => {
        oItem.oFreeSlots = data.result;
        oItem.bShowFreeSlots = true;
      })
      .catch(() => {
        oItem.bShowFreeSlots = true;
      });
  }

  onGetWaitTime(oItem: any) {
    this.oAPIService
      .send2ServerP(
        "WaitTime/" +
          oItem.ENTITYID +
          "/" +
          oItem.SERVICEID +
          "/" +
          oItem.QUEUEN +
          "/" +
          oItem.CURRENTQN,
        true
      )
      .then((data: any) => {
        if (data === undefined) return;
        //      console.log(JSON.stringify(data));
        if (data.result[0].WAIT_TIME !== "-1")
          //        oItem.EstimatedWait = '--';
          //      else
          oItem.EstimatedWait = Number(data.result[0].WAIT_TIME);
        oItem.UsersInQ = data.result[0].USERSINQ;
      });
  }

  onExchangeWith(oItem: any, oFreeItem: any) {
    this.oAPIService
      .send2ServerP(
        "exfreeq/" +
          oItem.ENTITYID +
          "/" +
          oItem.SERVICEID +
          "/" +
          oItem.QUEUEN +
          "/" +
          oFreeItem.UID +
          "/" +
          oFreeItem.AVAILABLEQN,
        true
      )
      .then((data: any) => {
        this.showFreeSlots(oItem, 0);
        if (data._error) {
          //        console.log("Exchanged Failed: " + data._error);
          //TODO add TOAST
          alert("sorry, requested Q# was taken.");
          return;
        }
        oItem.QUEUEN = data.result[0].QUEUEN;
        oItem.QUEUEN_T = this.padLeft(oItem.QUEUEN, "0", 3);
        //TODO add TOAST
        oItem.bShowFreeSlots = false;
        if (this.bShowWaitTime) this.onGetWaitTime(oItem);
      });
  }

  onExchangeQN(oItem: any) {
    this.oRouter.navigateByUrl("exchange", { state: oItem });
  }

  onGiveUpQ(oItem: any) {
    this.oAPIService
      .send2ServerP(
        "leaveq/" + oItem.ENTITYID + "/" + oItem.SERVICEID + "/" + oItem.QUEUEN,
        true
      )
      .then((data: any) => {
        if (data._error === undefined) {
          console.log("LeaveQ:  " + JSON.stringify(data));
          try {
            super.setConnect(false);
          } catch (err) {
            //console.log(err);
          }
          this.ngOnInit();
        } else {
          if (bDebug) console.log(data);
        }
      });
  }
  onRequeue(oItem: any) {
    var iNow = Number(moment().format("Hmm"));
    if (iNow >= oItem._TC) {
      oItem.bClosed = true;
      oItem.bCanRequeue = false;
      return;
    }
    this.oAPIService
      .send2ServerP(
        "qagain/" + oItem.ENTITYID + "/" + oItem.SERVICEID + "/" + oItem.QUEUEN,
        true
      )
      .then((data: any) => {
        if (data._error === undefined) {
          console.log("Requeue:  " + JSON.stringify(data));
          oItem.QUEUEN = data.result[0].QUEUEN;
          oItem.QUEUEN_T = this.padLeft(oItem.QUEUEN, "0", 3);
          oItem.bCanRequeue = false;
          this.showFreeSlots(oItem, 0);
          this.checkIfCanRequeue(oItem);
        } else {
          if (bDebug) console.log(data);
        }
      });
  }

  checkIfCanRequeue(oItem: any) {
    this.oAPIService
      .send2ServerP(
        "canqagain/" +
          oItem.ENTITYID +
          "/" +
          oItem.SERVICEID +
          "/" +
          oItem.QUEUEN,
        true
      )
      .then((data: any) => {
        oItem.bCanRequeue = data.result[0].COUNT == 0;
      });
  }

  isClosedNow(oItem: any) {
    oItem.bClosed = true;
    oItem._BusinessHours.forEach((o:any) => {
      var iNow = Number(moment(o.LOCAL_TIME).format("Hmm"));
      var iTO = Number(o.TIME_OPEN);
      var iTC = Number(o.TIME_CLOSE);
      if (iNow >= iTO && iNow <= iTC) {
        oItem.TIME_OPEN = iTO;
        oItem.TIME_CLOSE = iTC;
        oItem.bClosed = false;
        this.subscribe("CQ/" + o.ENTITYID + "/" + o.SERVICEID);
        oItem._TC = iTC;
      }
      oItem.bLoading = false;
    });
  }
  setCanQueueUpNow(oItem: any) {
    oItem.bLoading = true;
    try {
      this.oAPIService
        .send2ServerP("q/opened/" + oItem.ENTITYID + "/" + oItem.SERVICEID)
        .then((data: any) => {
          //     console.log("setCanQueueUpNow: " + JSON.stringify(data.result));
          if (data.result.length > 0) {
            oItem._bQOpened = true;
            oItem._BusinessHours = data.result;
            this.isClosedNow(oItem);
          }
        });
    } catch (err) {
      console.error(err);
    }
  }
}
