import { Injectable, Inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { sURL } from "../app/properties";
import { sIMAGE_URL } from "../app/properties";
import { Global_Variables } from "../app/properties";
import { sEndPoint } from "../env.properties";

@Injectable({
  providedIn: "root",
})
export class APIService {
  static instance: APIService;

  getUserID() {
    return Global_Variables.iUserID;
  }
  getUserType() {
    return Global_Variables.sUserType;
  }

  constructor(@Inject(HttpClient) public http: HttpClient) {
    console.log("END POINT********", sEndPoint)
  }

  mergeJSON(target: any, add: any) {
    function isObject(obj:any) {
      if (typeof obj == "object") {
        for (var k in obj) {
          if (obj.hasOwnProperty(k)) {
            return true; // search for first object prop
          }
        }
      }
      return false;
    }
    for (var key in add) {
      if (add.hasOwnProperty(key)) {
        if (target[key] && isObject(target[key]) && isObject(add[key])) {
          this.mergeJSON(target[key], add[key]);
        } else {
          target[key] = add[key];
        }
      }
    }
    return target;
  }

  performLogin(sService: string, data: any): Promise<any> {
    // if (Global_Variables.isConnected == false) {
    //   this.showNetworkError();
    //   return Promise.reject("No Network");
    // }
    return this.http.post(sEndPoint + sService, data).toPromise();
    //      .map(res => res.json()).toPromise();
  }
  getHeaders(): any {
    // console.log("*** Setting Header Token: ", Global_Variables.token?.['access_token']);
    var oHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Authorization": "Bearer " + Global_Variables.token?.['access_token'],
      // "ks-x-authorization": "Bearer: " + Global_Variables.token?.['access_token'],
    });
    return { headers: oHeaders };
  }
  // async showNetworkError() {
  //   this.alertCtrl.create({
  //     message: 'No Network',
  //     subHeader: 'Pls check internet connection',
  //     buttons: ['Dismiss']
  //   }).then(alert => alert.present() );
  // }
  send2Server(url: string, bPost: boolean = false, oItem: any = null): any {
    // if (Global_Variables.isConnected == false) {
    //   this.showNetworkError();
    //   return Promise.reject("No Network");
    // }
    if (bPost) {
      return this.http.post(url, oItem, this.getHeaders()).toPromise();
      //   .map(res => res.json()).toPromise();
      // return this.http.post(sURL, oItem, this.getHeaders())
      //   .map(res => res.json()).toPromise();
    }
    return this.http.get(url, this.getHeaders()).toPromise();
    // return this.http.get(sURL, this.getHeaders())
    //   .map(res => res.json()).toPromise();
  }

  getURL() {
    return sURL + Global_Variables.iUserID + "/";
  }

  send2ServerNoHeader(
    url: string,
    bPost: boolean = false,
    oItem: any = null
  ): any {
    if (bPost) {
      return this.http.post(url, oItem).toPromise();
    }
    return this.http.get(url).toPromise();
  }
  send2ServerC(
    sPartialURL: string,
    bPost: boolean = false,
    oItem: any = null
  ): any {
    // if (Global_Variables.isConnected == false) {
    //   this.showNetworkError();
    //   return Promise.reject("No Network");
    // }
    if (bPost) {
      return this.http
        .post(sURL + sPartialURL, oItem, this.getHeaders())
        .toPromise();
      // return this.http.post(sURL + sPartialURL, oItem, this.getHeaders())
      //   .map(res => res.json()).toPromise();
    }
    return this.http.get(sURL + sPartialURL, this.getHeaders()).toPromise();
    // return this.http.get(sURL + sPartialURL, this.getHeaders())
    //   .map(res => res.json()).toPromise();
  }

  send2ServerP(
    sPartialURL: string,
    bPost: boolean = false,
    oItem: any = null
  ): any {
    console.log(sPartialURL);
    // if (Global_Variables.isConnected == false) {
    //   this.showNetworkError();
    //   return Promise.reject("No Network");
    // }
    if (bPost) {
      return this.http
        .post(this.getURL() + sPartialURL, oItem, this.getHeaders())
        .toPromise();
      // return this.http.post(this.getURL() + sPartialURL, oItem, this.getHeaders())
      //   .map(res => res.json()).toPromise();
    }
    return this.http
      .get(this.getURL() + sPartialURL, this.getHeaders())
      .toPromise();
    // return this.http.get(this.getURL() + sPartialURL, this.getHeaders())
    //   .map(res => res.json()).toPromise();
  }

  send2ServerPlainText(
    sPartialURL: string,
    bPost: boolean = false,
    oItem: any = null
  ): any {
    // if (Global_Variables.isConnected == false) {
    //   this.showNetworkError();
    //   return Promise.reject("No Network");
    // }
    if (bPost) {
      return this.http
        .post(this.getURL() + sPartialURL, oItem, this.getHeaders())
        .toPromise();
    }
    return this.http
      .get(this.getURL() + sPartialURL, this.getHeaders())
      .toPromise();
  }

  fileUpload(sUrl: string, formData: any): any {
    // if (Global_Variables.isConnected == false) {
    //   this.showNetworkError();
    //   return Promise.reject("No Network");
    // }
    let headers = new Headers();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("enctype", "multipart/form-data");
    headers.append("Accept", "application/json");
    console.log(formData);
    //    let options = new RequestOptions({ headers: headers });
    return new Promise((resolve, reject) => {
      var xhr = new XMLHttpRequest();
      // for(var i = 0; i < files.length; i++) {
      //     formData.append("uploads[]", files[i], files[i].name);
      // }

      xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
          if (xhr.status == 200) {
            //            console.log(xhr.response);
            resolve(xhr.response);
          } else {
            reject(xhr.response);
          }
        }
      };
      //path = 'upload/' ;
      xhr.open("POST", sUrl, true);
      xhr.send(formData);
    });
  }
  buildImageURL(oItem: any, i: number = -1) {
    let img = oItem.IMAGE_URL;
    if (img === undefined || img === null) return "";
    if (img.startsWith("http://") || img.startsWith("https://")) return img;
    if (i != -1) return sIMAGE_URL + i + "/" + img;
    return sIMAGE_URL + oItem.ID + "/" + img;
  }
  sort(oData: any, oFields: any) {
    // this.oAPIService.sort ( this.oItems, [{field: 'PICKUPON', direction: 'desc' }, {field: 'PICKUPSTART', direction: 'desc' }, {field: 'BOOKINGREF', direction: 'desc'}]) ;
    oData.sort(function (a:any, b:any) {
      for (var i = 0; i < oFields.length; i++) {
        var retval =
          a[oFields[i].field] < b[oFields[i].field]
            ? -1
            : a[oFields[i].field] > b[oFields[i].field]
            ? 1
            : 0;
        if (oFields[i].direction == "desc") {
          retval = retval * -1;
        }
        if (retval !== 0) {
          return retval;
        }
      }
      return 0
    });
  }
  stringToDate(_date: string, _format: string, _delimiter: string) {
    // stringToDate("17/9/2014","dd/MM/yyyy","/");
    // stringToDate("9/17/2014","mm/dd/yyyy","/")

    var formatLowerCase = _format.toLowerCase();
    var formatItems = formatLowerCase.split(_delimiter);
    var dateItems = _date.split(_delimiter);
    var monthIndex = formatItems.indexOf("mm");
    var dayIndex = formatItems.indexOf("dd");
    var yearIndex = formatItems.indexOf("yyyy");
    var month = parseInt(dateItems[monthIndex]);
    month -= 1;
    var formatedDate = new Date(
      parseInt(dateItems[yearIndex]),
      month,
      parseInt(dateItems[dayIndex])
    );
    return formatedDate;
  }
  setTime(oDate: Date, sTime: string) {
    //       tests = ['01.25 PM', '01:25pm', '1:25 PM', '11.35 PM', '12.45 PM', '01.25 AM', '11.35 AM', '12.45 AM'],
    var timeReg = /(\d+)[\.|:](\d+)\s?(\w+)/;
    var parts:any = sTime.match(timeReg);
    var hours = /am/i.test(parts[3])
      ? (function (am) {
          return am < 12 ? am : 0;
        })(parseInt(parts[1], 10))
      : (function (pm) {
          return pm < 12 ? pm + 12 : 12;
        })(parseInt(parts[1], 10));
    var minutes = parseInt(parts[2], 10);
    oDate.setHours(hours);
    oDate.setMinutes(minutes);
    oDate.setSeconds(0);
    return oDate;
  }
  setTime24Hrs(oDate: Date, sTime: string): Date {
    var s = sTime.split(":");
    var hours = parseInt(s[0]);
    var minutes = parseInt(s[1]);
    oDate.setHours(hours);
    oDate.setMinutes(minutes);
    return oDate;
  }
  /*
    isFuture(date): boolean {
      return date.diff(moment.now()) > 0;
    }
    isTodayOrFuture(date) {
      date = this.stripTime(date);
      return date.diff(this.stripTime(moment.now())) >= 0;
    }
  
    stripTime(date) {
      date = moment(date);
      date.hours(0);
      date.minutes(0);
      date.seconds(0);
      date.milliseconds(0);
      return date;
    }
  */
}
