import { NgModule } from '@angular/core';
//import { CommonModule } from '@angular/common';
import { TimeFormatPipe } from './time-format.pipe';
import { TruncatePipe } from './truncate.pipe';
import { HoursMinutesSecondsPipe } from './hours-minutes-seconds.pipe';
@NgModule({
	declarations: [TimeFormatPipe,
    TruncatePipe,
    HoursMinutesSecondsPipe],
	imports: [],
	exports: [TimeFormatPipe,
    TruncatePipe,
    HoursMinutesSecondsPipe]
})
export class PipesModule {}
