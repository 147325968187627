import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";

import { AuthGuardService } from "./auth-guard.service";
import { EntitiesComponent } from "./entities/entities.component";
import { ServicesComponent } from "./services/services.component";
import { BusinessHoursComponent } from "./business-hours/business-hours.component";
import { MyAppointmentsComponent } from "./my-appointments/my-appointments.component";
import { QueueStatusComponent } from "./queue-status/queue-status.component";
import { AppointmentComponent } from "./appointment/appointment.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { ReachUsComponent } from "./reach-us/reach-us.component";
import { TakeQueueComponent } from "./take-queue/take-queue.component";
import { ClinicsComponent } from "./clinics/clinics.component";
import { NotSupportedComponent } from "./not-supported/not-supported.component";

const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "not_supported",
    component: NotSupportedComponent,
  },
  {
    path: "clinics",
    component: ClinicsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "entities",
    component: EntitiesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "services",
    component: ServicesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "businesshours",
    component: BusinessHoursComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "queuestatus",
    component: QueueStatusComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "appointment",
    component: AppointmentComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "myappointments",
    component: MyAppointmentsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "contactus",
    component: ContactUsComponent,
  },
  {
    path: "reachus",
    component: ReachUsComponent,
  },
  {
    path: "takequeue",
    component: TakeQueueComponent,
  },
  {
    path: "**",
    redirectTo: "/login",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
