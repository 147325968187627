import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'time24to12Format'
})
export class Time24to12FormatPipe implements PipeTransform {
  transform(time: any): any {
    let ap = "am";
    let t = +time;
    if (t < 59)
      t += 1200;
    else {
      if (t > 1159)
        ap = "pm";
      if (t > 1259) {
        t -= 1200;
      }
    }
    let sT = "" + t;
    let position = sT.length - 2;
    return time + " ( " + sT.substring(0, position) + ":" + sT.substring(position) + " " + ap + " ) ";
  }
}
