import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Global_Variables } from '../../app/properties';
import { APIService } from '../api.service';
import { Location } from '@angular/common';
// import { MatGridList, MatGridTile } from '@angular/material/grid-list';
// import { MatList, MatListItem } from '@angular/material/list';
import { TitleBarService } from '../title-bar.service';

@Component({
  selector: 'app-business-hours',
  templateUrl: './business-hours.component.html',
  styleUrls: ['./business-hours.component.scss']
})
export class BusinessHoursComponent implements OnInit {

  sTitle = 'Working Hours';
  public onConnectionStatusChange(sMqttStatus:any) {
    this.oTBS.titleChange({ title: this.sTitle, status: sMqttStatus });
  }

  //  oEntity: any;
  oService: any;
  bLoaded = false;
  oToday: any = [];
  oEntities: any = [];

  constructor(private oTBS: TitleBarService, public oLocation: Location, public oRoute: Router, public oAPIService: APIService
  ) {
    this.oService = this.oLocation.getState();
    console.log(this.oService);
  }
  ngOnInit(): void {

    this.onConnectionStatusChange('');
    this.oAPIService.send2ServerP("services/" + this.oService.ENTITYID + "/" + this.oService.ID + "/wh/today").then((data:any) => {
      this.oToday = data.result;
      console.log(this.oToday.length)
    });
    this.oAPIService.send2ServerP("services/" + this.oService.ID + "/wh/fetch").then((data:any) => {
      this.oEntities = this.groupByDay(data.result);
      this.bLoaded = true;
    });
  }
  groupByDay(oEntities:any) {
    var groups = oEntities.reduce(function (o:any, a:any) {
      var m = a.DAYOFWEEK;
      (o[m]) ? o[m].data.push(a) : o[m] = { DAYOFWEEK: m, data: [a], DOW: Global_Variables.DOW[Number(m)] };
      return o;
    }, {});
    var result = Object.keys(groups).map(function (k) {
      return groups[k];
    });
    //    console.log ( result ) ;
    return result;
  }
}
