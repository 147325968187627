import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { UserLocation } from "./user.location";
// import { Position, Coordinates } from "node-geocoder";

@Injectable({
  providedIn: "root",
})
export class GeoLocationService {
  location$: Subject<UserLocation|null> = new Subject();
  errors$: Subject<string> = new Subject();

  private api = navigator.geolocation;

  constructor() {}

  getLocation() {
    if (!navigator.geolocation) {
      this.errors$.next("not-available");
    }

    navigator.geolocation.getCurrentPosition(
      this.success.bind(this),
      this.failed.bind(this),
      {
        enableHighAccuracy: true,
        timeout: 5000,
      }
    );
  }

  private success(data: GeolocationPosition) {
    const payload = this.transformData(data.coords);

    this.location$.next(payload);
    this.errors$.next("");
  }

  private failed(e:any) {
    this.errors$.next(e.code === 1 ? "permission" : "failed");
    this.location$.next(null);
  }

  private transformData(data: any): UserLocation {
    return {
      latitude: data.latitude === undefined ? null : data.latitude,
      longitude: data.longitude === undefined ? null : data.longitude,
      altitude: data.altitude === undefined ? null : data.altitude,
      accuracyLatitude: data.accuracy
        ? this.metersToLatitude(data.accuracy)
        : null,
      accuracyLongitude: data.accuracy
        ? this.metersToLongitude(data.accuracy)
        : null,
      accuracyAltitude: data.altitudeAccuracy || null,
    };
  }

  private metersToLatitude(value: number): number {
    return value / (111321.38 * Math.cos((value * Math.PI) / 180));
  }

  private metersToLongitude(value: number): number {
    return value / 111000;
  }
}
