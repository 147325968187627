<!-- <mat-toolbar class="header">
    <mat-toolbar-row>
        <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
            <mat-icon>menu</mat-icon>
          </button>
      <span>Working Hours</span>
  </mat-toolbar-row>
</mat-toolbar> -->

<div *ngIf="bLoaded">
  <div class="entity-title">
    {{oService.ENAME}}
    <!-- <br>{{oEntity.ADDRESS}} -->
  </div>
  <!-- <div *ngIf="oToday.length == 0">
    <div>Sorry we are closed today.</div>
  </div> -->
<!-- 

  <mat-card appearance="outlined">
    <mat-card-header>
      <mat-card-title *ngIf="oToday.length > 0">Open Today</mat-card-title>
      <mat-card-title *ngIf="oToday.length == 0">Sorry we are closed today</mat-card-title>
      <mat-card-contents *ngIf="oToday.length > 0">
        <mat-card-title *ngFor="let o of oToday">{{o.TIME_OPEN | timeFormat }} - {{o.TIME_CLOSE | timeFormat }}
        </mat-card-title>
      </mat-card-contents>
    </mat-card-header>
  </mat-card>
  <mat-grid-list cols="4" *ngFor="let g of oEntities">
    <mat-grid-tile>{{g.DOW}}</mat-grid-tile>
    <mat-grid-tile colspan="3">
      <mat-list>
        <mat-list-item *ngFor="let o of g.data" class="time">
          <div>{{o.TIME_OPEN | timeFormat }} -
            {{o.TIME_CLOSE | timeFormat }}</div>
        </mat-list-item>
      </mat-list>
    </mat-grid-tile>
  </mat-grid-list>
</div>
 -->

 <div class="container">
  <mat-card class="custom-card" appearance="outlined">
    <mat-card-header>
      <mat-card-title *ngIf="oToday.length > 0; else closedTitle">Open Today</mat-card-title>
      <ng-template #closedTitle>
        <mat-card-title>Sorry, we are closed today</mat-card-title>
      </ng-template>
    </mat-card-header>
    <mat-card-content *ngIf="oToday.length > 0">
      <mat-list>
        <mat-list-item *ngFor="let o of oToday" class="time">
          <div>{{o.TIME_OPEN | timeFormat }} - {{o.TIME_CLOSE | timeFormat }}</div>
        </mat-list-item>
      </mat-list>
    </mat-card-content>
  </mat-card>

  <div class="row">
    <div class="col-md-12">
      <mat-grid-list cols="4" rowHeight="1:1" *ngFor="let g of oEntities">
        <mat-grid-tile>{{g.DOW}}</mat-grid-tile>
        <mat-grid-tile colspan="3">
          <mat-list>
            <mat-list-item *ngFor="let o of g.data" class="time">
              <div>{{o.TIME_OPEN | timeFormat }} - {{o.TIME_CLOSE | timeFormat }}</div>
            </mat-list-item>
          </mat-list>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
  </div>
</div>
